let mwi = (config, utils) => {
    const LOG_PREFIX = '[OCM][MWI] '

    if (config.services.mwi.hasOwnProperty('code')) {
        if (config.debug || config.services.mwi.debug) {
            console.log(LOG_PREFIX + 'Running...')
        }

        if (!utils.allowPageType(config.services.mwi.page_types)) {
            if (config.debug || config.services.mwi.debug) {
                console.warn(LOG_PREFIX + 'Terminating due to page type')
            }
            return
        }

        if (!config.services.mwi.safari && utils.browser.isSafari) {
            if (config.debug || config.services.mwi.debug) {
                console.warn(LOG_PREFIX + 'Terminating due to Safari browser')
            }
            return
        }

        if (!config.services.mwi.mobile && utils.is_mobile) {
            if (config.debug || config.services.mwi.debug) {
                console.warn(LOG_PREFIX + 'Terminating due to mobile device')
            }
            return
        }

        if (!config.services.mwi.desktop && !utils.is_mobile) {
            if (config.debug || config.services.mwi.debug) {
                console.warn(LOG_PREFIX + 'Terminating due to desktop device')
            }
            return
        }

        let mwi_slot;

        utils.window.googletag.cmd.push(() => {
            mwi_slot = googletag.defineOutOfPageSlot(config.services.mwi.code, googletag.enums.OutOfPageFormat.INTERSTITIAL);
            if (mwi_slot) {
                // explicitly set css properties to avoid crap like mobile headers overlapping the vignette
                utils.loadStyle('ins[data-vignette-loaded] { position:fixed !important; z-index: 2147483647 !important }')

                if (config.debug || config.services.mwi.debug) {
                    console.log(LOG_PREFIX + 'Slot created')
                }

                mwi_slot.addService(googletag.pubads());

                if (utils.config.services.header_bidding.active || utils.config.services.lazyload.active) {
                    if (config.debug || config.services.mwi.debug) {
                        console.log(LOG_PREFIX + 'disableInitialLoad is on, refreshing')
                    }

                    utils.gptPubAdsReady().then(() => {
                        googletag.pubads().refresh([mwi_slot]);
                    })
                }
            }
        });
    } else {
        if (!config.services.mwi.hasOwnProperty('code') && (config.debug || config.services.mwi.debug)) {
            console.error(LOG_PREFIX + 'Ad unit code missing from config')
        }
    }
}

module.exports = mwi
