require("intersection-observer")
const LOG_PREFIX = '[OCM][inImage] '

module.exports = class inImage {
    utils
    config
    in_image_config
    window
    include_observers

    constructor(utils, config) {
        this.utils = utils
        this.config = config
        this.in_image_config = config.services.in_image
        this.include_observers = []
    }

    run() {
        if (this.config.debug) {
            console.log(LOG_PREFIX + 'Running...')
        }

        if (this.config.debug || this.in_image_config.debug) {
            console.info(LOG_PREFIX + 'Is mobile =>', this.utils.is_mobile)
        }

        for (const tag of this.in_image_config.tags) {
            if (tag.active) {
                if (this.config.debug || this.in_image_config.debug) {
                    console.info(LOG_PREFIX + 'Tag =>', tag)
                }

                this.showAdInImage(tag)
            }
        }
    }

    showAdInImage(tag) {
        // Get all images length
        let allImagesLength = Array.from(this.utils.window.document.querySelectorAll(`${tag.selector}`)).length;

        if (this.config.debug || this.in_image_config.debug) {
            console.info(LOG_PREFIX + 'This site has ', allImagesLength, ' images');
        }

        const checkImagesSrc = (allImagesLength, tag) => {
            if (this.config.debug || this.in_image_config.debug) {
                console.info(LOG_PREFIX + 'Start check images src');
            }

            // Get images which already loaded
            let images = Array.from(this.utils.window.document.querySelectorAll(`${tag.selector}`)).filter(img => img.currentSrc.indexOf('lazy_placeholder.gif') === -1 || (img.getAttribute('height') !== null && img.getAttribute('width') !== null));
            let imagesDone = [];
            if (this.config.debug || this.in_image_config.debug) {
                console.info(LOG_PREFIX + 'This site has', allImagesLength + ' images - images with filter = ' + images.length);
            }

            // Normal case for images
            if (allImagesLength === images.length) {
                if (this.config.debug || this.in_image_config.debug) {
                    console.info(LOG_PREFIX + 'Start - normal image without ll');
                }
                this.addInImageAd(images, tag)
            } else {
                // Case with ll - without height/width attributes
                if (this.config.debug || this.in_image_config.debug) {
                    console.info(LOG_PREFIX + 'Start - image with ll');
                }
                // Get images every second
                let checkForNewImages = setInterval(() => {
                    if (this.config.debug || this.in_image_config.debug) {
                        console.info(LOG_PREFIX + 'Start run Interval');
                    }

                    images = Array.from(this.utils.window.document.querySelectorAll(`${tag.selector}`)).filter(img => img.currentSrc.indexOf('lazy_placeholder.gif') === -1 || (img.getAttribute('height') !== null && img.getAttribute('width') !== null));
                    // Add images in new variable because images maybe change at interval function
                    if (allImagesLength !== imagesDone.length) {
                        if (this.config.debug || this.in_image_config.debug) {
                            console.info(LOG_PREFIX + 'Start checking');
                        }
                        let newImages = images
                        let countOfDeletedImages = 0
                        // At the first time all images push in imagesDone
                        if (newImages.length !== 0) {
                            if (imagesDone.length === 0) {
                                imagesDone = newImages
                            } else {
                                //Check all newImages with imagesDone
                                for (let i = 0; i < newImages.length; i++) {
                                    // Check if image exist at imagesDone
                                    const alreadyDoneImage = imagesDone.find((img) => newImages[i - countOfDeletedImages].currentSrc === img.currentSrc)

                                    if (alreadyDoneImage) {
                                        if (this.config.debug || this.in_image_config.debug) {
                                            console.info(LOG_PREFIX + 'Remove image ' + newImages[i - countOfDeletedImages].currentSrc)
                                        }
                                        newImages.splice(i - countOfDeletedImages, 1)
                                        countOfDeletedImages++
                                    } else {
                                        if (this.config.debug || this.in_image_config.debug) {
                                            console.info(LOG_PREFIX + 'Done image ' + newImages[i - countOfDeletedImages].currentSrc)
                                        }
                                        imagesDone.push(newImages[i - countOfDeletedImages])
                                    }
                                }
                            }
                        }
                        if (newImages.length > 0) {
                            this.addInImageAd(newImages, tag)
                        }
                    } else {
                        if (this.config.debug || this.in_image_config.debug) {
                            console.info(LOG_PREFIX + 'Stop run Interval');
                        }
                        clearInterval(checkForNewImages);
                    }
                }, 1000)

            }
        }

        // If not exist any image then wait dom loaded
        if (allImagesLength === 0) {
            if (this.config.debug || this.in_image_config.debug) {
                console.info(LOG_PREFIX + `Wait Loaded`);
            }

            this.utils.window.addEventListener('load', function (event) {
                allImagesLength = Array.from(event.currentTarget.window.document.querySelectorAll(`${tag.selector}`)).length;
                checkImagesSrc(allImagesLength, tag)
            })
        } else {
            checkImagesSrc(allImagesLength, tag)
        }

    }

    addInImageAd(images, tag) {
        if (this.config.debug || this.in_image_config.debug) {
            console.info(LOG_PREFIX + 'add InImage Ad, images: ' + images, ' tag:', tag)
        }
        for (let i = 0; i < images.length; i++) {
            let image = images[i]
            let new_left = 0
            let finallyHeight = 0
            let percentSize = 0
            let imageParent = image.parentElement
            let imageParent_width = 0
            if (imageParent) {
                imageParent_width = imageParent.getBoundingClientRect().width
            }

            let image_height = image.getBoundingClientRect().height.toString()
            let image_width = image.getBoundingClientRect().width.toString()

            //Weird WP image lazyloading plugin - Calculate height for lazyloaded image
            if (image.hasAttribute('data-lazy-type')) {
                if (this.config.debug || this.in_image_config.debug) {
                    console.info(LOG_PREFIX + 'image with attr data-lazy-type ', image)
                }
                image_width = (image.clientWidth) ? image.clientWidth.toString() : image_width
                imageParent_width = (imageParent.clientWidth) ? imageParent.clientWidth.toString() : imageParent_width

                if (image.getAttribute('height') && image.getAttribute('width') && (image.getBoundingClientRect().width === image.getBoundingClientRect().height)) {
                    if (this.config.debug || this.in_image_config.debug) {
                        console.info(LOG_PREFIX + 'this image has height and width')
                    }
                    percentSize = image.getAttribute('height') / image.getAttribute('width')
                    finallyHeight = (image.clientHeight) ? (image.clientHeight * percentSize) : image_height
                    image_height = finallyHeight.toString()
                }
            }

            // See if image needs x-axis adjustment after dom manipulation (left css attribute)
            if (imageParent_width > parseInt(image_width)) {
                new_left = (imageParent_width - parseInt(image_width)) / 2
            }

            if (this.config.debug || this.in_image_config.debug) {
                console.log(LOG_PREFIX + 'imageParent_width = ', imageParent_width, typeof imageParent_width)
                console.log(LOG_PREFIX + 'image_height = ', image_height, typeof image_height)
                console.log(LOG_PREFIX + 'imageParent_width > parseInt(image_width) = ', (imageParent_width > parseInt(image_width)))
                console.log(LOG_PREFIX + 'image_width = ', image_width, typeof image_width)
                console.log(LOG_PREFIX + 'new_left = ', new_left, typeof new_left)
                console.log(LOG_PREFIX + 'new_left in css = ', String(new_left) + 'px')
            }

            // available size in this version is when isActive:true
            let available_sizes = this.in_image_config.available_sizes;
            let adSizes = null;
            let renderAd = false;

            // decide which mode choose from available_size
            available_sizes = available_sizes.filter(size => size.isMobile === this.utils.is_mobile).reverse()

            for (let size of available_sizes) {
                // when widthImg from available_size is equal with image_width - AND same as image_height AND isActive flag is true
                if (image_width >= size['widthImg'] && image_height >= size['heightImg'] && size['isActive'] === true) {
                    if (this.config.debug || this.in_image_config.debug) {
                        console.info(LOG_PREFIX + `in_image_${i} has the conditions to render ad`, size);
                    }

                    try {
                        adSizes = JSON.parse(size.adSizes)[0];
                    } catch (e) {
                        break;
                    }

                    renderAd = true;
                    break
                }
            }

            if (renderAd) {
                if (this.config.debug || this.in_image_config.debug) {
                    console.log(LOG_PREFIX + `For div in_image_${i}, width:` + adSizes[0] + ' height:' + adSizes[1])
                }
                this.topStyleForButton(adSizes[1], i)
                // changed height for add padding bottom
                let adHeight = adSizes[1] + 6
                //wrapper group image and div with ad
                let wrapper = document.createElement('div');
                wrapper.setAttribute("id", `in_image_container_${i}`);
                wrapper.classList.add('ocm-inImage-container')
                wrapper.style = `height:${image_height}px; width:${image_width}px; margin: auto;`;
                image.parentElement.insertBefore(wrapper, image);
                wrapper.appendChild(image);
                if (!this.in_image_config.withoutLeft) {
                    image.style = `position:absolute; left:${String(new_left)}px; top:0`;
                }
                // div with ad
                let ad_div = `<div id="in_image_${i}" style="height:0;"></div>`;

                //wrapper group ad_div
                let wrapper_ad_div = document.createElement('div');
                wrapper_ad_div.classList.add('ocm-inImage', 'reveal-top')
                wrapper.appendChild(wrapper_ad_div);
                wrapper_ad_div.insertAdjacentHTML('beforeend', ad_div);

                this.utils.window.googletag = this.utils.window.googletag || {cmd: []}
                this.utils.window.googletag.cmd.push(() => {
                    let inImageSlot = this.utils.window.googletag.defineSlot(`${tag.ad_tag_url}`, [adSizes], `in_image_${i}`).addService(googletag.pubads())
                    this.utils.window.googletag.display(`in_image_${i}`)
                    if (!this.utils.config.services.lazyload.active && window.OCM.init_ad_server_set) {
                        this.utils.window.googletag.pubads().refresh([inImageSlot])
                    }
                    this.utils.window.googletag.pubads().addEventListener('slotRenderEnded', async (event) => {
                        if (!event.isEmpty && event.slot.getSlotElementId() === `in_image_${i}`) {
                            let containerDiv = wrapper_ad_div.parentElement
                            let containerId = containerDiv.getAttribute('id');

                            if (this.utils.browser.isSafari) {
                                await this.isInViewportInImage(i, wrapper_ad_div, new_left, adHeight)
                            } else {
                                let selectorContainer = document.querySelectorAll(`#in_image_container_${i}`)[0]
                                await this.includeObserverInImage(containerId, wrapper_ad_div, new_left, adHeight)
                                this.include_observers[containerId].observe(selectorContainer);
                            }
                        }
                    })
                })
            }
        }
    }

    includeObserverInImage(divId, wrapper_ad_div, new_left, adHeight) {
        IntersectionObserver.prototype.POLL_INTERVAL = 100;

        let include_observer_config = {
            root: null, // without standar element bacause tag.selector(info from DSG) is tag for every img and not container with article body
            rootMargin: '20px 0px',
            threshold: 0.5
        };

        this.include_observers[divId] = new IntersectionObserver((entries, self) => {
            entries.forEach(async (entry) => {
                if (divId === entry.target.id) {
                    if (typeof entry.isVisible === 'undefined') {
                        entry.isVisible = true
                    }

                    if (entry.isIntersecting) {
                        await this.addEffectAndCloseButton(wrapper_ad_div, new_left, adHeight)
                        self.unobserve(entry.target);
                    }
                }
            })
        }, include_observer_config);
    }

    isPhotoInViewport(elem_id) {
        let bounding
        let isSkyscraper = false
        let percentVisible = 0
        let element = document.getElementById(elem_id)

        if (!element || typeof element === 'undefined') {
            return false
        }

        bounding = element.getBoundingClientRect()
        isSkyscraper = (bounding.height >= 600)
        // Check if it's 100% in viewport
        if (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.bottom <= (this.utils.window.innerHeight || this.utils.window.document.documentElement.clientHeight) &&
            bounding.right <= (this.utils.window.innerWidth || this.utils.window.document.documentElement.clientWidth)
        ) {
            return true
        }

        // Check if some of it is not visible (from top)
        if (
            bounding.top < 0 &&
            bounding.left >= 0
        ) {
            percentVisible = ((bounding.height - Math.abs(bounding.top)) / bounding.height) * 100
            if (isSkyscraper) {
                if (percentVisible >= 67) {
                    return true
                }
            } else {
                if (percentVisible >= 51) {
                    return true
                }
            }
        }

        return false
    }

    isInViewportInImage(i, wrapper_ad_div, new_left, adHeight) {
        window.addEventListener("scroll", async () => {
            if (this.isPhotoInViewport(`in_image_container_${i}`)) {
                await this.addEffectAndCloseButton(wrapper_ad_div, new_left, adHeight)
            }
        })
    }

    addEffectAndCloseButton(wrapper_ad_div, new_left, adHeight) {
        wrapper_ad_div.classList.add('menu--animatable')
        if (!wrapper_ad_div.classList.contains("menu--visible")) {
            wrapper_ad_div.classList.add("menu--visible");
        } else {
            wrapper_ad_div.classList.remove('menu--visible');
        }

        let bgColor = this.in_image_config.bg_color

        if (!this.in_image_config.withoutLeft) {
            wrapper_ad_div.style = `left:${String(new_left)}px; height:${adHeight}px !important;background-color:${bgColor}`;
        } else {
            wrapper_ad_div.style = `width:100%;height:${adHeight}px !important;background-color:${bgColor}`;
        }
        let close_icon_container = document.createElement('div')
        let close_icon_span = document.createElement('button')

        if (this.utils.is_mobile) {
            close_icon_container.classList.add('close-icon-container-mobile')
            close_icon_span.classList.add('ocm-button-close-mobile')
        } else {
            close_icon_container.classList.add('close-icon-container')
            close_icon_span.classList.add('ocm-button-close')
        }

        if (!this.utils.is_mobile) {
            close_icon_container.addEventListener('mouseenter', () => {
                close_icon_span.classList.add('ocm-button-close-hover')

            })
            close_icon_container.addEventListener('mouseleave', () => {
                close_icon_span.classList.remove('ocm-button-close-hover')
            })
        }

        close_icon_container.append(close_icon_span)
        wrapper_ad_div.append(close_icon_container)

        close_icon_span.addEventListener('click', () => {
            wrapper_ad_div.classList.add('ocm-inImage-remove')
        })
    }

    topStyleForButton(heightAd, i) {
        this.utils.loadStyle('' +
            '          .ocm-button-close{\n' +
            '               padding: 0 !important;\n' +
            '               border-radius: 4px !important;\n' +
            '               display: block !important;\n' +
            '               position: relative !important;\n' +
            '               width: 30px !important;\n' +
            '               height: 30px !important;\n' +
            '               background: #fff !important;\n' +
            '               color: #4a968e !important;\n' +
            '               border: 1px solid #106e89 !important;\n' +
            '               outline: none !important;\n' +
            '               cursor: pointer !important;\n' +
            '           }\n' +
            '\n' +
            '          .ocm-button-close-mobile{\n' +
            '               padding: 0 !important;\n' +
            '               border-radius: 4px !important;\n' +
            '               display: block !important;\n' +
            '               position: relative !important;\n' +
            '               width: 26px !important;\n' +
            '               height: 100% !important;\n' +
            '               background: #fff !important;\n' +
            '               color: #4a968e !important;\n' +
            '               border: 1px solid #106e89 !important;\n' +
            '               outline: none !important;\n' +
            '               cursor: pointer !important;\n' +
            '           }\n' +
            '\n' +
            '           .ocm-button-close-hover:before{\n' +
            '                   width: 100% !important;\n' +
            '                   box-sizing: border-box !important;\n' +
            '                   margin:0 !important;\n' +
            '                   padding:0 !important;\n' +
            '            }\n' +
            '\n' +
            '            .ocm-button-close-hover:after{\n' +
            '                   width: 100% !important;\n' +
            '                   box-sizing: border-box !important;\n' +
            '                   margin:0 !important;\n' +
            '                   padding:0 !important;\n' +
            '            }\n' +
            '\n' +
            '            .ocm-button-close:before{\n' +
            '               box-sizing: border-box !important;\n' +
            '               content: \'\' !important;\n' +
            '               border-radius: 13px !important;\n' +
            '               position: absolute !important;\n' +
            '               left: 50% !important;\n' +
            '               top: 50% !important;\n' +
            '               width: 0;\n' +
            '               margin:0 !important;\n' +
            '               padding:0 !important;\n' +
            '               height: 1px !important;\n' +
            '               border-left: 8px solid currentColor !important;\n' +
            '               border-right: 8px solid currentColor !important;\n' +
            '               -webkit-transform: translate3d(-50%,-50%,0) rotate(-45deg) !important;\n' +
            '               transition: all .2s cubic-bezier(.25,.46,.45,.94) !important;\n' +
            '           }\n' +
            '\n' +
            '           .ocm-button-close:after{\n' +
            '               box-sizing: border-box !important;\n' +
            '               content: \'\' !important;\n' +
            '               border-radius: 13px !important;\n' +
            '               position: absolute !important;\n' +
            '               left: 50% !important;\n' +
            '               top: 50% !important;\n' +
            '               width: 0;\n' +
            '               margin:0 !important;\n' +
            '               padding:0 !important;\n' +
            '               height: 1px !important;\n' +
            '               border-left: 8px solid currentColor !important;\n' +
            '               border-right: 8px solid currentColor !important;\n' +
            '               -webkit-transform: translate3d(-50%,-50%,0) rotate(45deg) !important;\n' +
            '               transition: all .2s cubic-bezier(.25,.46,.45,.94) !important;\n' +
            '           }\n' +
            '\n' +
            '            .ocm-button-close-mobile:before{\n' +
            '               box-sizing: border-box !important;\n' +
            '               content: \'\' !important;\n' +
            '               border-radius: 13px !important;\n' +
            '               position: absolute !important;\n' +
            '               left: 50% !important;\n' +
            '               top: 50% !important;\n' +
            '               width: 0;\n' +
            '               margin:0 !important;\n' +
            '               padding:0 !important;\n' +
            '               height: 1px !important;\n' +
            '               border-left: 8px solid currentColor !important;\n' +
            '               border-right: 8px solid currentColor !important;\n' +
            '               -webkit-transform: translate3d(-50%,-50%,0) rotate(-45deg) !important;\n' +
            '               transition: all .2s cubic-bezier(.25,.46,.45,.94) !important;\n' +
            '           }\n' +
            '\n' +
            '           .ocm-button-close-mobile:after{\n' +
            '               box-sizing: border-box !important;\n' +
            '               content: \'\' !important;\n' +
            '               border-radius: 13px !important;\n' +
            '               position: absolute !important;\n' +
            '               left: 50% !important;\n' +
            '               top: 50% !important;\n' +
            '               width: 0;\n' +
            '               margin:0 !important;\n' +
            '               padding:0 !important;\n' +
            '               height: 1px !important;\n' +
            '               border-left: 8px solid currentColor !important;\n' +
            '               border-right: 8px solid currentColor !important;\n' +
            '               -webkit-transform: translate3d(-50%,-50%,0) rotate(45deg) !important;\n' +
            '               transition: all .2s cubic-bezier(.25,.46,.45,.94) !important;\n' +
            '           }\n' +
            '\n' +
            '          .ocm-inImage-container {\n' +
            '             position:relative; \n' +
            '             overflow: hidden;\n' +
            '          }\n' +
            '\n' +
            '          .ocm-inImage.menu--visible  {\n' +
            '              -webkit-transform: none;\n' +
            '              transform: none;\n' +
            '}\n' +
            '\n' +
            '          .ocm-inImage.menu--animatable {\n' +
            '             transition: all 1s ease-out;\n' +
            '}\n' +
            '         .ocm-inImage.menu--animatable > #in_image_' + i + ' {\n' +
            '           height: ' + heightAd + 'px !important; \n' +
            '           transition: height 1s ease-out;\n' +
            '}\n' +
            '          .ocm-inImage {\n' +
            '             overflow: visible;' +
            '             position:absolute; \n' +
            '             padding-top:3px; \n' +
            '             padding-bottom:3px; \n' +
            '             bottom:0 !important; \n' +
            '             height: 0px;\n' +
            '             width:100%; \n' +
            '             display: flex; \n' +
            '             align-items: center;\n' +
            '             justify-content: center;\n' +
            '          }\n' +
            '\n' +
            '          .ocm-inImage .close-icon-container {\n' +
            '            position: absolute;\n' +
            '            top: -37px;\n' +
            '            height: 20px;\n' +
            '            width: 30px;\n' +
            '            right: 9px;\n' +
            '            z-index: 10;\n' +
            '          }\n' +
            '\n' +
            '          .ocm-inImage .close-icon-container-mobile {\n' +
            '            position: absolute;\n' +
            '            top: -37px;\n' +
            '            height: 26px;\n' +
            '            width: 26px;\n' +
            '            right: 9px;\n' +
            '            z-index: 10;\n' +
            '          }\n' +
            '\n' +
            '          .ocm-inImage .close-icon {\n' +
            '            display: inline-block;\n' +
            '            height: 26px;\n' +
            '            width: 26px;\n' +
            '            cursor: pointer;\n' +
            '            stroke: #fff;\n' +
            '            margin: 3px;\n' +
            '          }\n' +
            '\n' +
            '          .ocm-inImage.reveal-top {\n' +
            '            opacity: 1;\n' +
            '          }\n' +
            '\n' +
            '          .ocm-inImage.reveal-top.ocm-inImage-remove {\n' +
            '            transition: opacity 0.5s linear;\n' +
            '            opacity: 0;\n' +
            '          }\n' +
            '\n' +
            '          .ocm-inImage .progress {\n' +
            '            opacity: 0;\n' +
            '            stroke-dasharray: 0, 120;\n' +
            '          }'
        )
    }
}
