const LOG_PREFIX = "[OCM][PageSense] "
let pageSense = async (utils) => {
  return new Promise((resolve, reject) => {
    let pageType = 'ROS';
    let subPaths = window.location.pathname.replace("/", "").split("/") || [];
    let customPaths = [];
    // Keep only String values in subPaths (removes null and empty)
    subPaths = subPaths.filter(String);
    // The actual page sense goes here

    if (subPaths.length === 0) {
      pageType = "Home";
    } else if (subPaths.length === 1) {
      if (subPaths[0].length < 20) {
        pageType = "Category";
      } else {
        pageType = 'Article'
      }
    } else if (subPaths.length === 2) {
      if (subPaths[0] === "category" || subPaths[0] === "tag" || subPaths[0].length < 20) {
        pageType = "Category";
      } else {
        pageType = 'Article'
      }
    } else if (subPaths.length >= 3) {
      if (subPaths[0] === "category" || subPaths[0] === "tag" || subPaths[0].length < 20) {
        pageType = 'Category'
      }
    }

    resolve(pageType)
  });
};
module.exports = pageSense;