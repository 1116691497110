const LOG_PREFIX = "[OCM][Custom] "
//video waterfall: teads => aniview => inread_pb
// You need this if instream runs as outstream's passback
const Aniview = require('../../services/aniview')

let customCode = (utils) => {
  return new Promise((resolve, reject) => {
    utils.contentLoaded().then(() => {
      runOutstream(utils)
      ocmGoneMadHb(utils)
    })

    // after ocmPassback()
    window.oopb = () => {
      // initVΙ(document.querySelectorAll('.td-post-content p, .td-post-content ul, .td-post-content ol')[2])
    }
    
	//outbrain(document.querySelectorAll('.td-tags')[0])
    
    resolve(true)
  });
};

let runOutstream = (utils) => {
  (function(w, d) {
    try {
      d = w.top.document || d; w = w.top.document ? w.top : w;
    } catch (e) {}
    var ttag = function() {
      // Check v1 outstream service for excluded urls and append them in the array below
      const exclude_urls = [
        //  {'url': ''},
      ]

      for (let x = 0; x < exclude_urls.length; x++) {
        if (w.location.href.includes(exclude_urls[x].url)) {
          return
        }
      }

      // You need to find these 2 numbers (pm @Katerina, @peter, @Theo)
      w.teads.page(77234).placement(95727, {
        slider: {
          allow_corner_position: false, 
          allow_top_position: false
        }, 
        "css":"margin: 0px 0px 25.998px;", // The margin should reflect the styles of the articles paragraphs
        "format":"inread",
        "slot":{
          "insertInside": true, // If there is a dedicated div by the publisher, then we use insertInside, else available options are insertAfter, insertBefore
          "selector":".td-post-content p, .td-post-content ul, .td-post-content ol", // The target to inject the teads video tag
          "minimum":3 // If the target is a multiple node result (e.g. .article > p), then supply the minimum (starts counting from 1, not 0)
        }
      }).passback(() => {
        
        // Runs after instream service and only if no video ad came back
        window.OCM.oipb = () => {
           ocmPassback(utils, document.querySelectorAll('.td-post-content p, .td-post-content ul, .td-post-content ol')[1])
         }

        utils.config.services.aniview.active = true
        const av = new Aniview(utils, utils.config)
        av.run()      
      }).serve();
    };
    if (w.teads && w.teads.page) { ttag(); }
    else if (!w.teadsscript) {
      var s = document.createElement('script');
      s.src = 'https://s8t.teads.tv/media/format/v3/teads-format.min.js';
      s.async = true; s.onload = ttag; w.teadsscript = d.getElementsByTagName('head')[0].appendChild(s);
    } else {
      w.teadsscript.addEventListener('load', ttag);
    }
  })(window, document); 
}

let ocmPassback = (utils, selector) => {
  if (!selector) {
    return
  }

  selector.insertAdjacentHTML('beforebegin', `<div id="ocm-outstream" style="margin-bottom:1.55em"></div>`)

  window.googletag = window.googletag || {cmd: []};
  googletag.cmd.push(function() {

    // Change the ad unit path
    let inread_slot = googletag.defineSlot('/73437317/babyradio.gr/inread_pb', [1,1], 'ocm-outstream').addService(googletag.pubads());
    googletag.display("ocm-outstream");
  });
}

let initVI = (slot) => {
  utils.loadStyle('#vi-stories-sponsor-container {display: none!important;}');

  var vidiv = document.createElement('div');
  vidiv.id = 'vi-div';
  if (!utils.is_mobile) {
    vidiv.style.width = '80%';
  }
  vidiv.style.margin = '0 auto';

  if (slot) {
    slot.appendChild(vidiv);
  }

  (function (v, i) {
    var scp = v.createElement("script"),
        config = {
          ChannelID: '5d8390ef28a0611e523a52aa',
          AdUnitType: '2',
          PublisherID: '133044126840134',
          PlacementID: 'pltz9FljnNNm8YU6PEn',
          DivID: 'vi-div',
          IAB_Category: 'IAB1',
          Keywords: '',
          Language: 'en-us',
          BG_Color: '',
          Text_Color: '',
          Font: '',
          FontSize: '',
        };
    scp.src = 'https://s.vi-serve.com/tagLoader.js';
    scp.type = "text/javascript";
    scp.onload = function () {
      i[btoa('video intelligence start')].init(config);
    };
    (v.getElementsByTagName('head')[0] || v.documentElement.appendChild(v.createElement('head'))).appendChild(scp);
  })(document, window);
}

/*
let outbrain = (slot) => {
  if (!slot) {
    return
  }

  let widget = '<div class="OUTBRAIN" data-ob-contentUrl="' + window.location.href + '" data-widget-id="GS_1" style="margin:0 auto 60px" data-ob-installation-key="CYOCM2IDF6569I0FK92EEOL10"></div>'
  let script = document.createElement('script')
  script.async = 'async'
  script.src = 'https://widgets.outbrain.com/outbrain.js'
  slot.parentNode.insertBefore(script, slot)

  slot.insertAdjacentHTML('afterend', widget)
}
*/



let ocmGoneMadHb = (utils) => {
  let waitForVariable = (utils.config.services.header_bidding.adsqRTDModuleEnabled) ? 'ADSQ' : 'ocmpbjs'
  console.log('waitForVariable', waitForVariable)
  utils.waitFor(waitForVariable, () => {
    console.log('ADSQ?', window.ADSQ)
    // inject a simple div
    let div = `<div id="ocm-gone-mad"></div>`
    let target = utils.doc_body || document.body
    target.insertAdjacentHTML('beforeEnd', div)

    let adunit = window.OCM.ad_units.filter((ad) => {
      return (ad.code === 'ocm-gone-mad')
    })[0]

    // Auction for it
    window.ocmpbjs.que.push(() => {
      window.ocmpbjs.addAdUnits({
        code: adunit.code,
        mediaTypes: adunit.mediaTypes,
        bids: adunit.bids,
      })

      window.ocmpbjs.requestBids({
        adUnitCodes: ['ocm-gone-mad'],
        bidsBackHandler: (bidResponses, timeout, auctionId) => {
          console.log('bidResponses', bidResponses)
          // filter out rejected bids
          Object.keys(bidResponses).forEach(adUnit => {
            bidResponses[adUnit].bids = bidResponses[adUnit].bids.filter(b => b.statusMessage !== 'bidRejected' && b.status !== 'bidRejected');
          });

          // filter out empty bids
          Object.keys(bidResponses).forEach(adUnit => {
            if (!bidResponses[adUnit].bids.length) {
              delete bidResponses[adUnit]
            }
          });

          if (Object.keys(bidResponses).length > 0) {
            console.log('bidResponses before directRenderBids', bidResponses)
            directRenderBids(bidResponses, utils)
          }
        },
        timeout: utils.config.services.header_bidding.bidderTimeout
      });
    })
  })
}

let directRenderBids = (bidResponses, utils) => {
  for (let adUnitCode in bidResponses) {
    if (utils.config.debug || utils.config.services.header_bidding.debug) {
      console.log(LOG_PREFIX + 'Found bid for #' + adUnitCode)
    }

    const divId = adUnitCode;
    const adDiv = window.document.getElementById(divId)

    if (adDiv) {
      if (utils.config.debug || utils.config.services.header_bidding.debug) {
        console.log(LOG_PREFIX + 'Ad div = ', adDiv)
      }

      let frmEl = window.document.createElement('iframe');

      frmEl.setAttribute('id', divId + '_ocm_hb')
      frmEl.setAttribute('style', 'border:0;overflow:hidden;margin:15px auto;display:block;');
      frmEl.scrolling = 'no'
      frmEl.width = '0'
      frmEl.height = '0'

      adDiv.appendChild(frmEl);

      let iframeDoc = frmEl.contentWindow.document
      let adServerTargeting = window.ocmpbjs.getAdserverTargetingForAdUnitCode(divId)
      //       if (utils.config.debug || utils.config.services.header_bidding.debug) {
      console.log(LOG_PREFIX + 'adServerTargeting', adServerTargeting)
      //       }

      // If any bidders return any creatives
      if (adServerTargeting && adServerTargeting['hb_adid']) {
        if (utils.config.debug || utils.config.services.header_bidding.debug) {
          console.log(LOG_PREFIX + 'Rendering ad for ' + divId)
        }
        window.ocmpbjs.renderAd(iframeDoc, adServerTargeting.hb_adid)
      }
    } else {
      if (utils.config.debug || utils.config.services.header_bidding.debug) {
        console.log(LOG_PREFIX + 'Ad div #' + divId + ' not found')
      }
    }
  }
}

module.exports = customCode;