const LOG_PREFIX = '[OCM][Sticky] '

module.exports = class Sticky {
    utils
    config
    sticky_config
    is_mobile
    counter
    skin_slot
    skin_empty

    constructor(utils, config) {
        this.utils = utils
        this.config = config
        this.sticky_config = config.services.sticky
        this.counter = 0
        this.skin_slot = null
        this.skin_empty = null
    }

    async run() {
        if (this.config.debug || this.sticky_config.debug) {
            console.log(LOG_PREFIX + 'Running...')
        }

        // Wait for googletag.pubadsready && DOMContentLoaded
        await this.utils.gptPubAdsReady()

        for (let ad of this.sticky_config.ads) {
            if (!ad.active) {
                continue
            }

            if (!this.deviceAllowed(ad.conditions)) {
                if (this.config.debug || this.sticky_config.debug) {
                    console.log(LOG_PREFIX + 'Ad (' + ad.code + ') not meant to run in current device')
                }
                continue
            }

            if (!this.utils.allowPageType(ad.conditions.page_types)) {
                if (this.config.debug || this.sticky_config.debug) {
                    console.log(LOG_PREFIX + 'Ad (' + ad.code + ') not meant to run in current page type')
                }
                continue
            }

            if (this.isExcluded(ad.conditions.exclude_urls)) {
                if (this.config.debug || this.sticky_config.debug) {
                    console.log(LOG_PREFIX + 'Ad (' + ad.code + '). Inside excluded url. Terminating service.')
                }
                continue
            }

            // if (ad.conditions.hide_if_skin) {
            //     if (this.skin_empty) {
            //         this.structure(ad)
            //     }
            // } else {
            await this.structure(ad)
            // }
        }
    }

    async determineSkinExists() {
        return new Promise((resolve, reject) => {
            let ad_slots = []

            if (this.config.debug || this.sticky_config.debug) {
                console.log(LOG_PREFIX + 'Checking if skin ad exists')
            }

            if (this.skin_slot) {
                resolve(true);
            }

            ad_slots = this.utils.window.googletag.pubads().getSlots().filter((slot) => {
                if ((slot.getSlotElementId().includes('skin') || slot.getAdUnitPath().includes('skin'))) {
                    this.skin_slot = slot
                    return true
                }

                return false
            })

            resolve((ad_slots.length > 0))
        })
    }

    async checkSkinEmpty() {
        if (this.config.debug || this.sticky_config.debug) {
            console.log(LOG_PREFIX + 'Since skin exists ## ' + this.skin_slot.getAdUnitPath() + ' ##, checking if it comes up empty')
        }

        let promise = new Promise((resolve, reject) => {
            let end = (result) => {
                resolve(result)
            }

            if (this.skin_empty !== null) {
                end(this.skin_empty);
            }

            this.utils.window.googletag.cmd.push(() => {
                googletag.pubads().addEventListener('slotRenderEnded', (event) => {
                    if (event.slot.getAdUnitPath() === this.skin_slot.getAdUnitPath()) {
                        if (this.config.debug || this.sticky_config.debug) {
                            console.log(LOG_PREFIX + 'Skin isEmpty = ' + event.isEmpty)
                        }

                        end(event.isEmpty)
                    }
                })
            })
        })

        return await promise
    }

    async structure(ad) {
        this.counter++

        if (this.config.debug || this.sticky_config.debug) {
            console.log(LOG_PREFIX + 'Structuring sticky with code: ', ad.code);
        }

        if (!ad.bg_color) {
            ad.bg_color = 'rgba(27,27,27,.75)'
        }

        switch (ad.position) {
            case 'left':
                this.leftStyles()
                await this.side(ad)
                break;
            case 'right':
                this.rightStyles()
                await this.side(ad)
                break;
            case 'top':
                this.topStyles(ad.bg_color)
                this.topBottom(ad)
                break;
            case 'bottom':
                this.bottomStyles(ad.bg_color)
                this.topBottom(ad)
                break;
            default:
                break;
        }
    }

    async side(ad) {

        if (this.config.debug || this.sticky_config.debug) {
            console.log(LOG_PREFIX + 'Trying to render side sticky with code: ', ad.code);
        }

        this.styleForXButton();

        let isOpen = true
        let final_width
        let final_height

        let target = this.utils.window.document.querySelectorAll('body *')[0]

        let ocm_sticky = document.createElement('div')
        ocm_sticky.classList.add('ocm-sticky', ad.position)

        let container = document.createElement('div')
        container.classList.add('ocm-sticky-container')

        let content = document.createElement('div')
        content.classList.add('ocm-sticky-content')

        let ad_div = document.createElement('div')
        ad_div.id = 'ocm_sticky_' + this.counter
        ad_div.classList.add('ocm-sticky-ad')

        let ad_script = document.createElement('script')
        ad_script.text = 'googletag.cmd.push(function() {' +
            'try { var ' + ad_div.id + '_slot = googletag.defineSlot("' + ad.code + '", ' + ad.sizes + ', "' + ad_div.id + '");' +
            'if (' + ad_div.id + '_slot) { ' +
            '  ' + ad_div.id + '_slot.addService(googletag.pubads());' +
            '  googletag.display("' + ad_div.id + '");' +
            '}} catch(err) {}'
        if (!this.config.services.lazyload.active && this.config.services.header_bidding.active) {
            ad_script.text += 'if (window.OCM.init_ad_server_set) { googletag.pubads().refresh([' + ad_div.id + '_slot]); }'
        }
        ad_script.text += '})'

        if (this.config.services.lazyload.active) {
            if (!this.config.services.lazyload.ad_load_offsets.filter((adunit) => {
                return (adunit.path === ad.code && adunit.offset === -1)
            }).length) {
                ad_div.setAttribute('data-lazyincluded-by-ocm', '')
                ad_div.setAttribute('data-oau-code', ad.code)
            }
        }

        // let ocm_div = document.createElement('div')
        // ocm_div.classList.add('ocm-dm')
        // ocm_div.innerHTML = '<a href="https://orangeclickmedia.com" target="_blank">OCM Digital Advertising</a>'

        let close_container = document.createElement('div')
        close_container.classList.add('ocm-close-icon-container')

        let close_span = document.createElement('button')
        close_span.classList.add('ocm-button-close')

        if (!this.utils.is_mobile) {
            close_container.addEventListener('mouseenter', () => {
                close_span.classList.add('ocm-button-close-hover')

            })
            close_container.addEventListener('mouseleave', () => {
                close_span.classList.remove('ocm-button-close-hover')
            })
        }

        if (ad.conditions.hide_if_skin) {
            await this.determineSkinExists().then(async (result) => {
                if (result) {
                    this.skin_empty = await this.checkSkinEmpty()
                    if (this.skin_empty) {
                        close_container.append(close_span)
                        // container.append(ocm_div)
                        content.append(ad_div)
                        container.append(content)
                        ad_div.append(ad_script)
                        ocm_sticky.append(container)
                        target.parentNode.insertBefore(ocm_sticky, target)
                    }
                } else {
                    close_container.append(close_span)
                    // container.append(ocm_div)
                    content.append(ad_div)
                    container.append(content)
                    ad_div.append(ad_script)
                    ocm_sticky.append(container)
                    target.parentNode.insertBefore(ocm_sticky, target)
                }
            })
        } else {
            close_container.append(close_span)
            // container.append(ocm_div)
            content.append(ad_div)
            container.append(content)
            ad_div.append(ad_script)
            ocm_sticky.append(container)
            target.parentNode.insertBefore(ocm_sticky, target)
        }

        this.utils.window.googletag.pubads().addEventListener('slotRenderEnded', (event) => {
            if (this.config.debug || this.sticky_config.debug) {
                console.log(LOG_PREFIX + 'Slot render ended. Event: ', event, ' isEmpty: ', event.isEmpty, ' slotElementId: ', event.slot.getSlotElementId(), ' divId: ', ad_div.id);
            }
            if (!event.isEmpty && event.slot.getSlotElementId() === ad_div.id) {
                final_width = event.size[0]
                final_height = event.size[1]

                if (final_height === 1 && final_width === 1) {
                    return
                }

                ocm_sticky.style.width = String(final_width) + 'px'
                ocm_sticky.style.height = String(final_height) + 'px'
                ocm_sticky.style.marginTop = '-' + String(final_height / 2) + 'px'

                content.style.width = String(final_width) + 'px'
                content.style.height = String(final_height) + 'px'

                if (ad.conditions.retract) {
                    ocm_sticky.addEventListener('mouseenter', (e) => {
                        if (isOpen) {
                            return
                        }

                        if (ad.position === 'left') {
                            ocm_sticky.style.marginLeft = '0px'
                        } else if (ad.position === 'right') {
                            ocm_sticky.style.marginRight = '0px'
                        }

                        isOpen = true

                        e.stopPropagation();
                    })

                    ocm_sticky.addEventListener('mouseleave', (e) => {
                        if (!isOpen) {
                            return
                        }

                        setTimeout(() => {
                            if (ad.position === 'left') {
                                ocm_sticky.style.marginLeft = '-' + String(Math.round(final_width * 0.33)) + 'px'
                            } else if (ad.position === 'right') {
                                ocm_sticky.style.marginRight = '-' + String(Math.round(final_width * 0.33)) + 'px'
                            }
                            isOpen = false
                        }, 1000)

                        e.stopPropagation();
                    })
                }
            }
        })

        this.utils.window.googletag.pubads().addEventListener('impressionViewable', (event) => {
            if (this.config.debug || this.sticky_config.debug) {
                console.log(LOG_PREFIX + 'Event after impression viewable ' + event + ' event condition ' + event.slot.getSlotElementId() === ad_div.id)
            }
            if (event.slot.getSlotElementId() === ad_div.id) {

                // if there is no close button attach to ocm sticky then we append one
                const ocm_sticky_close_container = container && container.childNodes && Array.from(container.childNodes).find(el => el.className === 'ocm-close-icon-container')
                if (!ocm_sticky_close_container) {
                    container.append(close_container)
                }

                close_span.addEventListener('click', (e) => {
                    if (ad.position === 'left') {
                        ocm_sticky.style.marginLeft = '-' + String(final_width) + 'px'
                    } else if (ad.position === 'right') {
                        ocm_sticky.style.marginRight = '-' + String(final_width) + 'px'
                    }
                    setTimeout(() => {
                        ocm_sticky.remove()
                    }, 500)
                    e.stopPropagation();
                })

                if (ad.conditions.retract && final_width !== 1 && final_height !== 1) {
                    setTimeout(function () {
                        if (ad.position === 'left') {
                            ocm_sticky.style.marginLeft = '-' + String(Math.round(final_width * 0.33)) + 'px'
                        } else if (ad.position === 'right') {
                            ocm_sticky.style.marginRight = '-' + String(Math.round(final_width * 0.33)) + 'px'
                        }

                        isOpen = false
                    }, 1000)
                }
            }
        })
    }

    topBottom(ad) {
        this.styleForXButton();

        let target = this.utils.window.document.querySelectorAll('body *')[0]

        // demo-sticky-mobile
        // let target = (ad.position === 'bottom') ? this.utils.window.document.querySelectorAll('.view > .iframe')[0]
        //     : this.utils.window.document.querySelectorAll('.view > .iframe > nav')[0]

        let container = document.createElement('div')
        container.classList.add('ocm-sticky', ad.position)

        let ad_div = document.createElement('div')
        ad_div.id = 'ocm_sticky_' + this.counter
        // demo-sticky-mobile
        // this.utils.loadStyle('#ocm_sticky_' + this.counter + ' { display:flex }')

        let ad_script = document.createElement('script')
        ad_script.text = 'googletag.cmd.push(function() {' +
            '  try { var ' + ad_div.id + '_slot = googletag.defineSlot("' + ad.code + '", ' + ad.sizes + ', "' + ad_div.id + '");' +
            '  if (' + ad_div.id + '_slot) { ' +
            '    ' + ad_div.id + '_slot.addService(googletag.pubads());' +
            '    googletag.display("' + ad_div.id + '");' +
            '  }} catch(err) {}'
        if (!this.config.services.lazyload.active && this.config.services.header_bidding.active) {
            ad_script.text += 'if (window.OCM.init_ad_server_set) { googletag.pubads().refresh([' + ad_div.id + '_slot]); }'
        }
        ad_script.text += '})'

        if (this.config.services.lazyload.active) {
            if (!this.config.services.lazyload.ad_load_offsets.filter((adunit) => {
                return (adunit.path === ad.code && adunit.offset === -1)
            }).length) {
                ad_div.setAttribute('data-lazyincluded-by-ocm', '')
                ad_div.setAttribute('data-oau-code', ad.code)
            }
        }

        // let ocm_div = document.createElement('div')
        // ocm_div.classList.add('ocm-dm')
        // ocm_div.innerHTML = '<a href="https://orangeclickmedia.com" target="_blank">OCM Digital Advertising</a>'

        let close_container = document.createElement('div')
        close_container.classList.add('close-icon-container')

        let close_span = document.createElement('button')
        close_span.classList.add('ocm-button-close')

        if (!this.utils.is_mobile) {
            close_container.addEventListener('mouseenter', () => {
                close_span.classList.add('ocm-button-close-hover')

            })
            close_container.addEventListener('mouseleave', () => {
                close_span.classList.remove('ocm-button-close-hover')
            })
        }

        close_container.append(close_span)
        container.append(close_container)
        // container.append(ocm_div)
        ad_div.append(ad_script)
        container.append(ad_div)

        // demo-sticky-mobile
        // if (ad.position === 'bottom') {
        //     target.append(container)
        // } else {
        //     target.parentNode.insertBefore(container, target)
        // }

        target.parentNode.insertBefore(container, target)

        this.utils.window.googletag.pubads().addEventListener('slotRenderEnded', (event) => {
            if (!event.isEmpty && event.slot.getSlotElementId() === ad_div.id) {
                container.classList.add('reveal-' + ad.position)
                let width = event.size[0]
                let height = event.size[1]

                if (ad.conditions.device === 'desktop') {
                    width += 6
                    height += 6
                    if (!['top', 'bottom'].includes(ad.position)) {
                        container.style.width = String(width) + 'px'
                        container.style.height = String(height) + 'px'
                    }
                }

                setTimeout(() => {
                    close_container.addEventListener('click', () => {
                        container.classList.remove('reveal-' + ad.position)
                    })
                }, 2000)
            }
        })
    }

    styleForXButton() {
        this.utils.loadStyle('' +
            '          .ocm-button-close{\n' +
            '               padding: 0 !important;\n' +
            '               border-radius: 4px !important;\n' +
            '               display: block !important;\n' +
            '               position: relative !important;\n' +
            '               width: 30px !important;\n' +
            '               height: 30px !important;\n' +
            '               background: #fff !important;\n' +
            '               color: #4a968e !important;\n' +
            '               border: 1px solid #106e89 !important;\n' +
            '               outline: none !important;\n' +
            '               cursor: pointer !important;\n' +
            '           }\n' +
            '\n' +
            '           .ocm-button-close-hover:before{\n' +
            '                   width: 100% !important;\n' +
            '                   box-sizing: border-box !important;\n' +
            '                   margin:0 !important;\n' +
            '                   padding:0 !important;\n' +
            '            }\n' +
            '\n' +
            '            .ocm-button-close-hover:after{\n' +
            '                   width: 100% !important;\n' +
            '                   box-sizing: border-box !important;\n' +
            '                   margin:0 !important;\n' +
            '                   padding:0 !important;\n' +
            '            }\n' +
            '\n' +
            '            .ocm-button-close:before{\n' +
            '               box-sizing: border-box !important;\n' +
            '               content: \'\' !important;\n' +
            '               border-radius: 13px !important;\n' +
            '               position: absolute !important;\n' +
            '               left: 50% !important;\n' +
            '               top: 50% !important;\n' +
            '               width: 0;\n' +
            '               margin:0 !important;\n' +
            '               padding:0 !important;\n' +
            '               height: 1px !important;\n' +
            '               border-left: 8px solid currentColor !important;\n' +
            '               border-right: 8px solid currentColor !important;\n' +
            '               -webkit-transform: translate3d(-50%,-50%,0) rotate(-45deg) !important;\n' +
            '               transition: all .2s cubic-bezier(.25,.46,.45,.94) !important;\n' +
            '           }\n' +
            '\n' +
            '           .ocm-button-close:after{\n' +
            '               box-sizing: border-box !important;\n' +
            '               content: \'\' !important;\n' +
            '               border-radius: 13px !important;\n' +
            '               position: absolute !important;\n' +
            '               left: 50% !important;\n' +
            '               top: 50% !important;\n' +
            '               width: 0;\n' +
            '               margin:0 !important;\n' +
            '               padding:0 !important;\n' +
            '               height: 1px !important;\n' +
            '               border-left: 8px solid currentColor !important;\n' +
            '               border-right: 8px solid currentColor !important;\n' +
            '               -webkit-transform: translate3d(-50%,-50%,0) rotate(45deg) !important;\n' +
            '               transition: all .2s cubic-bezier(.25,.46,.45,.94) !important;\n' +
            '           }'
        )
    }

    leftStyles() {
        this.utils.loadStyle('' +
            '            .ocm-sticky.left {\n' +
            '                z-index: 2147483641;\n' +
            '                display: block;\n' +
            '                position: fixed;\n' +
            '                top:50%;\n' +
            '                left:0px;\n' +
            '                -webkit-transition: margin-left 0.5s ease-in-out !important;\n' +
            '                transition: margin-left 0.5s ease-in-out !important;\n' +
            '            }\n' +
            '            .ocm-sticky.left .ocm-sticky-container {\n' +
            '                z-index: 2147483643;\n' +
            '                background-color: #fff;\n' +
            '                border: 0 solid #afaeae;\n' +
            '                box-sizing: content-box;\n' +
            '                color: #000;\n' +
            '                height: 100%;\n' +
            '                overflow: visible;\n' +
            '                position: absolute;\n' +
            '                text-align: left;\n' +
            '                visibility: visible;\n' +
            '            }\n' +
            '            .ocm-sticky.left .ocm-sticky-container .ocm-sticky-content {\n' +
            '              overflow: hidden;\n' +
            '              visibility: visible;\n' +
            '            }\n' +
            '            .ocm-sticky.left .ocm-sticky-container .ocm-sticky-content .ocm-sticky-ad {\n' +
            '              border: 0;\n' +
            '              height:100%;\n' +
            '              visibility: visible;\n' +
            '              width:100%;\n' +
            '            }\n' +
            '            .ocm-sticky.left .ocm-sticky-container .ocm-close-icon-container {\n' +
            '              z-index: 2147483645;\n' +
            '              cursor: pointer;\n' +
            '              display: block;\n' +
            '              height: 31px;\n' +
            '              left: 3px;\n' +
            '              position: absolute;\n' +
            '              top: -35px;\n' +
            '              width: 32px;\n' +
            '            }\n' +
            '            .ocm-sticky.left .ocm-sticky-container .ocm-close-icon-container .ocm-close-icon .progress {\n' +
            '                opacity: 0;\n' +
            '                stroke-dasharray: 0,120;\n' +
            '                transition: 0.5s cubic-bezier(0.165, 0.775, 0.145, 1.020);\n' +
            '            }' +
            '')
    }

    rightStyles() {
        this.utils.loadStyle('' +
            '            .ocm-sticky.right {\n' +
            '                z-index: 2147483641;\n' +
            '                display: block;\n' +
            '                position: fixed;\n' +
            '                top:50%;\n' +
            '                right:0px;\n' +
            '                -webkit-transition: margin-right 0.5s ease-in-out !important;\n' +
            '                transition: margin-right 0.5s ease-in-out !important;\n' +
            '            }\n' +
            '            .ocm-sticky.right .ocm-sticky-container {\n' +
            '                z-index: 2147483643;\n' +
            '                background-color: #fff;\n' +
            '                border: 0 solid #afaeae;\n' +
            '                box-sizing: content-box;\n' +
            '                color: #000;\n' +
            '                height: 100%;\n' +
            '                overflow: visible;\n' +
            '                position: absolute;\n' +
            '                text-align: left;\n' +
            '                visibility: visible;\n' +
            '            }\n' +
            '            .ocm-sticky.right .ocm-sticky-container .ocm-sticky-content {\n' +
            '              overflow: hidden;\n' +
            '              visibility: visible;\n' +
            '            }\n' +
            '            .ocm-sticky.right .ocm-sticky-container .ocm-sticky-content .ocm-sticky-ad {\n' +
            '              border: 0;\n' +
            '              height:100%;\n' +
            '              visibility: visible;\n' +
            '              width:100%;\n' +
            '            }\n' +
            '            .ocm-sticky.right .ocm-sticky-container .ocm-close-icon-container {\n' +
            '              z-index: 2147483645;\n' +
            '              cursor: pointer;\n' +
            '              display: block;\n' +
            '              height: 32px;\n' +
            '              right: 3px;\n' +
            '              position: absolute;\n' +
            '              top: -35px;\n' +
            '              width: 32px;\n' +
            '            }\n' +
            '            .ocm-sticky.right .ocm-sticky-container .ocm-close-icon-container .ocm-close-icon .progress {\n' +
            '                opacity: 0;\n' +
            '                stroke-dasharray: 0,120;\n' +
            '                transition: 0.5s cubic-bezier(0.165, 0.775, 0.145, 1.020);\n' +
            '            }' +
            '')
    }

    topStyles(bg_color) {
        this.utils.loadStyle('' +
            '          .ocm-sticky.top {\n' +
            '            position: fixed;\n' +
            // demo-sticky-mobile
            // '            position: sticky;\n' +
            '            background-color:' + bg_color + ';\n' +
            '            width: 100%;\n' +
            '            padding: 8px 0;\n' +
            '            z-index: 2147483641;\n' +
            '            top: -1000px;\n' +
            '            left: 0;\n' +
            '            right: 0;\n' +
            '            transition: top .5s ease-in-out !important;\n' +
            '            text-align: center;\n' +
            '          }\n' +
            '\n' +
            '          .ocm-sticky.reveal-top {\n' +
            '            top: 0;\n' +
            '          }\n' +
            '\n' +
            '          .ocm-sticky.reveal-top div[data-oau-code] {\n' +
            '            margin: 0 auto;\n' +
            '          }\n' +
            '\n' +
            '          .ocm-sticky.top .close-icon-container {\n' +
            '            position: absolute;\n' +
            '            bottom: -35px;\n' +
            '            right: 4px;\n' +
            '            z-index: 10;\n' +
            '          }\n' +
            '\n' +
            '          .ocm-sticky.top .close-icon {\n' +
            '            display: inline-block;\n' +
            '            height: 32px;\n' +
            '            width: 32px;\n' +
            '            cursor: pointer;\n' +
            '            stroke: #fff;\n' +
            '          }\n' +
            '\n' +
            '          .ocm-sticky.top .progress {\n' +
            '            opacity: 0;\n' +
            '            stroke-dasharray: 0, 120;\n' +
            '          }')
    }

    bottomStyles(bg_color) {
        this.utils.loadStyle('' +
            '          .ocm-sticky.bottom {\n' +
            '            position: fixed;\n' +
            // demo-sticky-mobile
            // '            position: sticky;\n' +
            '            background-color:' + bg_color + ';\n' +
            '            width: 100%;\n' +
            '            padding: 8px 0;\n' +
            '            z-index: 2147483641;\n' +
            '            bottom: -1000px;\n' +
            '            left: 0px;\n' +
            '            right: 0px;\n' +
            '            transition: bottom .5s ease-in-out !important;\n' +
            '            text-align: center;\n' +
            '          }\n' +
            '\n' +
            '          .ocm-sticky.reveal-bottom {\n' +
            '            bottom: 0px;\n' +
            '          }\n' +
            '          .ocm-sticky.reveal-bottom div[data-oau-code] {' +
            '            margin: 0 auto;' +
            '          }\n' +
            '\n' +
            '          .ocm-sticky.bottom .close-icon-container {\n' +
            '            position: absolute;\n' +
            '            top: -35px;\n' +
            '            right: 4px;\n' +
            '            z-index: 10;\n' +
            '          }\n' +
            '\n' +
            '          .ocm-sticky.bottom .close-icon {\n' +
            '            display: inline-block;\n' +
            '            height: 32px;\n' +
            '            width: 32px;\n' +
            '            cursor: pointer;\n' +
            '            stroke: #fff;\n' +
            '          }\n' +
            '\n' +
            '          .ocm-sticky.bottom .progress {\n' +
            '            opacity: 0;\n' +
            '            stroke-dasharray: 0, 120;\n' +
            '          }')
    }

    deviceAllowed(conditions) {
        return (this.utils.is_mobile && conditions.device === 'mobile') ||
            (!this.utils.is_mobile && conditions.device === 'desktop' && this.utils.screen_width >= (conditions.min_width * this.utils.window.devicePixelRatio));
    }

    isExcluded(excluded_urls) {
        // If excluded url, then don't run
        if (typeof excluded_urls !== undefined && excluded_urls.length) {
            if (excluded_urls.find((url) => {
                return this.utils.window.location.href.includes(url);
            })) {
                return true;
            }
        }

        return false;
    }
}
