const LOG_PREFIX = '[OCM][HB] '
const ImaPlayer = require('../includes/ima-player')

module.exports = class HeaderBidding {
    utils
    config
    hb_config
    mode
    ratio
    screen_width
    screen_height
    analytics_adapters = []
    viewedBids = []

    constructor(utils, config) {
        this.utils = utils
        this.config = config
        this.hb_config = config.services.header_bidding

        this.mode = this.hb_config.functionality
        if (this.mode === 'lazyload_v2' && this.utils.browser.isSafari) {
            this.mode = 'lazyload_v1'
        }

        this.utils.window.OCM.hb_mode = this.mode

        this.ratio = 1

        this.utils.window.ocmpbjs = this.utils.window.ocmpbjs || {que: []}

        this.loadPrebidLib()

        const articleAliases = [
            'Article', 'article', 'news_article', 'Recipe', 'Teen Article'
        ]

        if ((this.hb_config.adsqRTDModuleEnabled || this.window?.ADSQ?.response) && articleAliases.includes(this.utils.window.OCM.pageType)) {
            // Run this on a 10% frequency using random number generator
            if (Math.random() < 0.1) {
                this.loadAdformCookie()
            }
        }

        if (!this.utils.window.OCM.adBlocked && this.utils.window.OCM.hasPurposeOneConsent) {
            this.prebidEventListeners()
        }
    }

    async prepare(isLazyLoad) {
        return new Promise(async (resolve, reject) => {
            isLazyLoad = (typeof isLazyLoad === 'undefined') ? false : isLazyLoad

            if (this.hb_config.bidderTimeout === 1) {
                if (this.config.debug || this.hb_config.debug) {
                    console.log(LOG_PREFIX + 'Timeout was set to 1ms, terminating process.')
                }

                return
            }

            // Preparation
            this.adjustBidderTimeout(isLazyLoad)

            this.buildAdUnits().then(() => {

                if (!this.utils.window.OCM.adBlocked) {
                    this.setupAnalytics()
                }

                this.configure()

                if (this.mode !== 'no_adserver') {
                    this.gptEventListeners()
                }

                resolve()
            })
        })
    }

    configure() {
        let customGranularity = {
            "buckets": [
                {
                    "precision": 2,
                    "min": 0.01,
                    "max": 10,
                    "increment": 0.01
                },
                {
                    "precision": 2,
                    "max": 20,
                    "increment": 0.1
                },
                {
                    "precision": 2,
                    "max": 50,
                    "increment": 0.5
                },
            ]
        }

        this.utils.window.ocmpbjs.que.push(() => {
            this.utils.window.ocmpbjs.onEvent('bidViewable', (bid) => {
                if (this.viewedBids.includes(bid.adId)) {
                    return
                }

                this.viewedBids.push(bid.adId)

                if (this.config.debug || this.hb_config.debug) {
                    console.log(LOG_PREFIX + 'bidViewable', bid)
                }

                const data = {
                    eventName: 'bidViewable',
                    adUnit: bid.adUnitCode,
                    auctionId: bid.auctionId
                }

                fetch('https://windrunner.orangeclickmedia.com/pba-viewability',{
                    method: 'POST',
                    headers: {
                        'Content-Type': 'text/plain'
                    },
                    body: JSON.stringify(data)
                }).then((response) => {
                    //response.json().then((json) => console.log(json))
                }).catch((err) => {
                    console.error(err)
                })
            })

            if (typeof this.config.uses_cmp === "undefined" || this.config.uses_cmp) {
                this.utils.window.ocmpbjs.setConfig({
                    consentManagement: {
                        gdpr: {
                            cmpApi: "iab",
                            timeout: this.hb_config.bidderTimeout + 20000,
                            defaultGdprScope: true,
                            rules: [
                                {
                                    purpose: "storage",
                                    enforcePurpose: true,
                                    enforceVendor: true,
                                    vendorExclusions: ['pubCommonId', 'ocmpba', 'sharedId'],
                                },
                                {
                                    purpose: 'basicAds',
                                    enforcePurpose: true,
                                    enforceVendor: true
                                }
                            ],
                        },
                        usp: {
                            cmpApi: "iab",
                            timeout: 100, // US Privacy timeout 100ms
                        },
                    }
                })
            }

            this.utils.window.ocmpbjs.setConfig({
                bidViewabilityIO: {
                    enabled: true,
                },
                auctionOptions: {
                    suppressStaleRender: true
                },
                floors: {
                    enforcement: {
                        floorDeals: false,
                        bidAdjustment: true
                    },
                    data: {
                        currency: this.hb_config.adServerCurrency,
                        schema: {
                            fields: ['mediaType']
                        },
                        values: {
                            'banner': 0.05,
                            'video': 0.30
                        }
                    }
                },
                ortb2: {
                    device: {
                        geo: {},
                        ext: {
                            data: {}
                        }
                    },
                    site: {
                        ext: {
                            data: {
                                hostname: this.utils.hostname.replace('www.', '')
                            }
                        },
                        content: {},
                        cat: []
                    },
                    user: {
                        ext: {
                            data: {}
                        },
                        geo: {}
                    },
                },
                firstPartyData: {
                    skipEnrichments: false
                },
                cache: {
                    url: 'https://prebid.adnxs.com/pbc/v1/cache'
                },
                priceGranularity: (this.hb_config.custom_granularity) ? customGranularity : 'high',
                realTimeData: this.setupRealTimeData(),
                gptPreAuction: {
                    enabled: true
                },
                schain: this.config.schain,
                currency: {
                    adServerCurrency: this.hb_config.adServerCurrency,
                    conversionRateFile: "https://cdn.orangeclickmedia.com/tech/libs/latest_currencies.txt",
                    defaultRates: {
                        "EUR": {
                            "AED": 3.805626,
                            "AFN": 92.831698,
                            "ALL": 116.968197,
                            "AMD": 411.521758,
                            "ANG": 1.875279,
                            "AOA": 530.432591,
                            "ARS": 171.618663,
                            "AUD": 1.548396,
                            "AWG": 1.86525,
                            "AZN": 1.760058,
                            "BAM": 1.958628,
                            "BBD": 2.100921,
                            "BDT": 105.975067,
                            "BGN": 1.949238,
                            "BHD": 0.392557,
                            "BIF": 2153.945747,
                            "BMD": 1.03625,
                            "BND": 1.431631,
                            "BOB": 7.1898,
                            "BRL": 5.606628,
                            "BSD": 1.040496,
                            "BTC": 6.398923e-5,
                            "BTN": 84.933132,
                            "BWP": 13.425877,
                            "BYN": 2.627378,
                            "BYR": 20310.50198,
                            "BZD": 2.097316,
                            "CAD": 1.392803,
                            "CDF": 2127.421281,
                            "CHF": 0.980987,
                            "CLF": 0.034634,
                            "CLP": 955.661229,
                            "CNY": 7.460479,
                            "COP": 5042.755679,
                            "CRC": 631.616057,
                            "CUC": 1.03625,
                            "CUP": 27.460628,
                            "CVE": 110.423787,
                            "CZK": 24.355714,
                            "DJF": 185.236365,
                            "DKK": 7.437395,
                            "DOP": 56.821611,
                            "DZD": 144.111447,
                            "EGP": 25.552786,
                            "ERN": 15.543752,
                            "ETB": 55.595372,
                            "FJD": 2.294724,
                            "FKP": 0.856839,
                            "GBP": 0.859611,
                            "GEL": 2.823809,
                            "GGP": 0.856839,
                            "GHS": 15.087249,
                            "GIP": 0.856839,
                            "GMD": 63.726415,
                            "GNF": 8965.919717,
                            "GTQ": 8.126386,
                            "GYD": 217.689881,
                            "HKD": 8.101777,
                            "HNL": 25.708472,
                            "HRK": 7.515198,
                            "HTG": 143.045936,
                            "HUF": 409.458649,
                            "IDR": 16286.79465,
                            "ILS": 3.546146,
                            "IMP": 0.856839,
                            "INR": 84.659529,
                            "IQD": 1518.599743,
                            "IRR": 43937.00419,
                            "ISK": 146.505065,
                            "JEP": 0.856839,
                            "JMD": 160.325997,
                            "JOD": 0.734721,
                            "JPY": 143.558465,
                            "KES": 127.230012,
                            "KGS": 87.511043,
                            "KHR": 4302.403708,
                            "KMF": 490.770502,
                            "KPW": 932.625091,
                            "KRW": 1387.404319,
                            "KWD": 0.318658,
                            "KYD": 0.867022,
                            "KZT": 484.292379,
                            "LAK": 18061.672221,
                            "LBP": 1573.462599,
                            "LKR": 382.376978,
                            "LRD": 159.582533,
                            "LSL": 17.606078,
                            "LTL": 3.059777,
                            "LVL": 0.626817,
                            "LYD": 5.077101,
                            "MAD": 11.107959,
                            "MDL": 19.974923,
                            "MGA": 4514.73851,
                            "MKD": 61.701939,
                            "MMK": 2185.07378,
                            "MNT": 3546.516481,
                            "MOP": 8.375401,
                            "MRO": 369.941108,
                            "MUR": 45.232128,
                            "MVR": 15.937176,
                            "MWK": 1067.976416,
                            "MXN": 20.043492,
                            "MYR": 4.64277,
                            "MZN": 66.144126,
                            "NAD": 17.605767,
                            "NGN": 459.327978,
                            "NIO": 37.452132,
                            "NOK": 10.298362,
                            "NPR": 135.890785,
                            "NZD": 1.667622,
                            "OMR": 0.400927,
                            "PAB": 1.040486,
                            "PEN": 4.010451,
                            "PGK": 3.666301,
                            "PHP": 58.795823,
                            "PKR": 233.542728,
                            "PLN": 4.694603,
                            "PYG": 7499.784498,
                            "QAR": 3.773002,
                            "RON": 4.91991,
                            "RSD": 117.47566,
                            "RUB": 62.864155,
                            "RWF": 1125.086989,
                            "SAR": 3.894124,
                            "SBD": 8.528952,
                            "SCR": 13.913739,
                            "SDG": 590.141752,
                            "SEK": 10.860896,
                            "SGD": 1.427336,
                            "SHP": 1.427326,
                            "SLE": 18.916642,
                            "SLL": 18885.658052,
                            "SOS": 589.108583,
                            "SRD": 32.194733,
                            "STD": 21448.284911,
                            "SVC": 9.103828,
                            "SYP": 2603.618548,
                            "SZL": 17.835948,
                            "THB": 37.025736,
                            "TJS": 10.457137,
                            "TMT": 3.626875,
                            "TND": 3.360039,
                            "TOP": 2.446742,
                            "TRY": 19.298749,
                            "TTD": 7.064754,
                            "TWD": 32.125615,
                            "TZS": 2416.535318,
                            "UAH": 38.427988,
                            "UGX": 3891.43946,
                            "USD": 1.03625,
                            "UYU": 40.886794,
                            "UZS": 11701.367505,
                            "VEF": 1076523.955306,
                            "VES": 10.592555,
                            "VND": 25699.002506,
                            "VUV": 122.112454,
                            "WST": 2.766275,
                            "XAF": 656.895259,
                            "XAG": 0.048803,
                            "XAU": 0.000592,
                            "XCD": 2.800518,
                            "XDR": 0.794935,
                            "XOF": 656.901608,
                            "XPF": 119.816416,
                            "YER": 259.261712,
                            "ZAR": 17.745989,
                            "ZMK": 9327.496513,
                            "ZMW": 17.610624,
                            "ZWL": 333.67211
                        }
                    }
                },
                userSync: {
                    syncsPerBidder: 5,
                    auctionDelay: 700,
                    filterSettings: {
                        iframe: {
                            bidders: ['iprom'],
                            filter: 'exclude'
                        },
                    },
                    userIds: [
                        {
                            name: "lotamePanoramaId"
                        },
                        {
                            name: "identityLink",
                            params: {
                                pid: "1258",
                            },
                            storage: {
                                type: "html5",
                                name: "idl_env",
                                expires: 90,
                                refreshInSeconds: 8 * 3600
                            }
                        },
                        {
                            name: "unifiedId",
                            params: {
                                url: "//match.adsrvr.org/track/rid?ttd_pid=pubmatic&fmt=json",
                                type: "html5",
                                name: "_unifiedid",
                                expires: 30
                            }
                        },
                        {
                            name: "criteo"
                        },
                        {
                            name: "id5Id",
                            params: {
                                partner: 347             // change to the Partner Number you received from ID5
                            },
                            storage: {
                                type: "html5",
                                name: "id5id",      // create a cookie with this name
                                expires: 90,             // cookie lasts for 90 days
                                refreshInSeconds: 28800 // refresh ID every 8 hours to ensure it's fresh
                            }
                        },
                        {
                            name: "sharedId",
                            storage: {
                                type: "html5",
                                name: "_pubcid",         // create a cookie with this name
                                expires: 365             // expires in 1 years
                            }
                        },

                    ],
                    aliasSyncEnabled: true,
                },
                maxRequestsPerOrigin: 4,
                bidderTimeout: Number(this.hb_config.bidderTimeout),
                timeoutBuffer: 400,
                useBidCache: true,
                suppressEmptyKeys: true,
                enableSendAllBids: false,
                targetingControls: {
                    alwaysIncludeDeals: true,
                },
                pageUrl: this.utils.window.location.protocol + '//' + this.utils.window.location.hostname + this.utils.window.location.pathname,
                ix: {
                    timeout: this.hb_config.bidderTimeout
                },
                yahoossp: {
                    mode: 'all',
                    singleRequestMode: true
                },
                rubicon: {
                    singleRequest: true
                }
            })

            const bidders = [
                {"bidder": "pubmatic", "domain": "pubmatic.com", "id": "157884"},
                {"bidder": "appnexus", "domain": "xandr.com", "id": "14808"},
                // {"bidder": "adform", "domain": "adform.com", "id": "2656"},
                // {"bidder": "openx", "domain": "openx.com", "id": "540861995"},
                // {"bidder": "rubicon", "domain": "rubiconproject.com", "id": "20086"},
                // {"bidder": "onetag", "domain": "onetag.com", "id": "5e5558fade26a9e"},
                // {"bidder": "criteo", "domain": "criteo.com", "id": "B-060738"},
                {"bidder": "teads", "domain": "teads.tv", "id": "16578"},
                {"bidder": "adyoulike", "domain": "adyoulike.com", "id": "7ddaba905b34014f9bd3fe2c57d6d46e"},
                {"bidder": "gumgum", "domain": "gumgum.com", "id": "14064"},
                {"bidder": "medianet", "domain": "media.net", "id": "8CU32RQ72"},
                {"bidder": "triplelift", "domain": "triplelift.com", "id": "9262"},
                // {"bidder": "unruly", "domain": "unrulygroup.com", "id": "6111915185540403805"},
                // {"bidder": "yahoossp", "domain": "yahoo.com", "id": "59049"},
                // {"bidder": "smartadserver", "domain": "smartadserver.com", "id": "3172"},
                // {
                //     "bidder": "sharethrough",
                //     "domain": "sharethrough.com",
                //     "id": ((window.location.hostname.indexOf('marinetraffic') > -1) ? "9e44aa3f" : "X2W2HNCU")
                // },
                //{"bidder": "ix", "domain": "indexexchange.com", "id": "188610"},
            ]

            bidders.forEach((entry) => {
                let cfg = (entry.bidder === 'appnexus') ? {
                    bidders: [entry.bidder],
                    "config": {
                        "schain": {
                            "validation": "relaxed",
                            "config": {
                                "ver": "1.0",
                                "complete": 1,
                                "nodes": [
                                    {
                                        "asi": `adops.gr`,
                                        "sid": this.config.adops_seller_id,
                                        "hp": 1
                                    }
                                ]
                            }
                        }
                    }
                } :
                {
                    bidders: [entry.bidder],
                    "config": {
                        "schain": {
                            "validation": "relaxed",
                            "config": {
                                "ver": "1.0",
                                "complete": 1,
                                "nodes": [
                                    this.config.schain.config.nodes[0],
                                    {
                                        "asi": `${entry.domain}`,
                                        "sid": `${entry.id}`,
                                        "hp": 1
                                    }
                                ]
                            }
                        }
                    }
                }

                this.utils.window.ocmpbjs.setBidderConfig(cfg)
            })

            this.utils.window.ocmpbjs.bidderSettings = this.setupBidderSettings(this.hb_config.custom_granularity)

            if (this.analytics_adapters.length) {
                this.utils.window.ocmpbjs.enableAnalytics(this.analytics_adapters)
            }
        })

        // Resize 1x1 bid impressions to actual size
        this.utils.window.ocmpbjs = this.utils.window.ocmpbjs || {que: []}
        this.utils.window.ocmpbjs.que.push(() => {
            this.utils.window.ocmpbjs.onEvent('bidWon', (bid) => {
                if (bid.mediaType === 'banner') {
                    const slot = this.utils.window.googletag.pubads().getSlots()
                        .find(s => (s.getAdUnitPath() === bid.adUnitCode && s.getTargeting('hb_adid')[0] === bid.adId));
                    if (!slot) {
                        return
                    }

                    const safeFrame = this.utils.window.document.querySelector(`div[id^="google_ads_iframe_${slot.getSlotId().getId().replace(/(\.|\/)/g, '\\$1')}"]`);

                    if (safeFrame) {
                        safeFrame.style.height = `${bid.height}px !important`;
                        safeFrame.style.width = `${bid.width}px !important`;
                    }

                    // Stickies stuff
                    let slot_el = this.utils.window.document.getElementById(slot.getSlotElementId())
                    if (slot_el && slot_el.classList.contains('ocm-sticky-ad')) {
                        let ocm_sticky_content = slot_el.parentNode
                        let ocm_sticky_container = ocm_sticky_content.parentNode
                        let ocm_sticky = ocm_sticky_container.parentNode
                        if (ocm_sticky.classList.contains('left') || ocm_sticky.classList.contains('right')) {
                            let close_container = document.createElement('div')
                            close_container.classList.add('ocm-close-icon-container')

                            let close_span = document.createElement('span')
                            close_span.classList.add('ocm-button-close')

                            if (!this.utils.is_mobile) {
                                close_container.addEventListener('mouseenter', () => {
                                    close_span.classList.add('ocm-button-close-hover')

                                })
                                close_container.addEventListener('mouseleave', () => {
                                    close_span.classList.remove('ocm-button-close-hover')
                                })
                            }

                            ocm_sticky.style.width = `${bid.width}px !important`
                            ocm_sticky.style.height = `${bid.height}px !important`
                            ocm_sticky.style.marginTop = `-${bid.height / 2}px`
                            if (ocm_sticky.classList.contains('right')) {
                                ocm_sticky_container.style.marginLeft = `-${bid.width}px`
                            }

                            ocm_sticky_content.style.width = `${bid.width}px !important`
                            ocm_sticky_content.style.height = `${bid.height}px !important`

                            // if there is no close button attach to ocm sticky then we append one
                            const ocm_sticky_close_container = ocm_sticky_container && ocm_sticky_container.childNodes && Array.from(ocm_sticky_container.childNodes).find(el => el.className === 'ocm-close-icon-container')
                            if (!ocm_sticky_close_container) {
                                close_container.append(close_span)
                                ocm_sticky_container.append(close_container)
                            }

                            close_span.addEventListener('click', (e) => {
                                if (ocm_sticky.classList.contains('left')) {
                                    ocm_sticky.style.marginLeft = '-' + String(bid.width) + 'px'
                                } else if (ocm_sticky.classList.contains('right')) {
                                    ocm_sticky.style.marginRight = '-' + String(bid.height) + 'px'
                                }

                                setTimeout(() => {
                                    ocm_sticky.remove()
                                }, 500)

                                e.stopPropagation();
                            })
                        }
                    }
                }
            })
        })

        if (!this.mode.includes('lazyload')) {
            this.utils.window.ocmpbjs.que.push(() => {
                this.utils.window.ocmpbjs.addAdUnits(this.utils.window.OCM.ad_units)

                if (this.hb_config.debug) {
                    console.log(LOG_PREFIX + 'Added this.utils.window.OCM.ad_units to prebid', this.utils.window.ocmpbjs.adUnits)
                }
                if (this.mode === 'no_adserver') {
                    this.utils.window.ocmpbjs.requestBids({
                        adUnitCodes: this.hb_config.adUnits.map((unit) => {
                            return unit.code
                        }),
                        bidsBackHandler: (bidResponses, timeout, auctionId) => {
                            if (this.config.debug || this.hb_config.debug) {
                                console.log(LOG_PREFIX + 'no_adserver bidResponses', bidResponses)
                            }

                            // filter out rejected bids
                            Object.keys(bidResponses).forEach(adUnit => {
                                bidResponses[adUnit].bids = bidResponses[adUnit].bids.filter(b => b.statusMessage !== 'bidRejected' && b.status !== 'bidRejected');
                            });

                            // filter out empty bids
                            Object.keys(bidResponses).forEach(adUnit => {
                                if (!bidResponses[adUnit].bids.length) {
                                    delete bidResponses[adUnit]
                                }
                            });

                            if (Object.keys(bidResponses).length > 0) {
                                this.directRenderBids(bidResponses)
                            }
                        }
                    })
                } else {
                    this.utils.window.ocmpbjs.requestBids({
                        bidsBackHandler: (bids, timeout, auctionId) => {
                            this.initAdServer([], false)
                        },
                    })
                }
            })
        }
    }

    initAdServer(slots, isLazyLoad = false) {
        if (isLazyLoad) {
            if (this.config.debug || this.hb_config.debug) {
                console.log(LOG_PREFIX + 'Calling initAdServer with slots:', slots)
            }

            let targetSlots = []
            for (const gas of slots) {
                let ad_element = this.utils.window.document.querySelector('[id="' + gas.getSlotElementId() + '"]')
                if (ad_element) {
                    ad_element.removeAttribute('data-lazyincluded-by-ocm')
                    ad_element.setAttribute('data-lazyloaded-by-ocm', '')
                    targetSlots.push(gas.getAdUnitPath())
                }
            }

            if (typeof slots !== 'undefined' && slots.length) {
                this.utils.window.googletag.cmd.push(() => {
                    this.utils.window.ocmpbjs.que.push(() => {
                        try {
                            this.utils.window.ocmpbjs.setTargetingForGPTAsync(targetSlots)

                            this.utils.window.OCM.pb_done = true

                            if (this.config.services.uam.active) {
                                if (this.utils.window.OCM.uam_done) {
                                    this.utils.window.googletag.pubads().refresh(slots)
                                } else {
                                    setTimeout(() => {
                                        this.utils.window.googletag.pubads().refresh(slots)
                                    }, 400);
                                }
                            } else {
                                this.utils.window.googletag.pubads().refresh(slots)
                            }
                        } catch (error) {
                            console.error(LOG_PREFIX, error)
                        }
                    })
                })
            }
        } else {
            if (this.config.debug || this.hb_config.debug) {
                console.log(LOG_PREFIX + 'Calling initAdServer for all slots')
            }

            this.utils.window.googletag.cmd.push(() => {
                this.utils.window.ocmpbjs.que.push(() => {
                    this.utils.window.ocmpbjs.setTargetingForGPTAsync()
                    this.utils.window.OCM.pb_done = true

                    if (this.config.services.uam.active) {
                        if (this.utils.window.OCM.uam_done) {
                            this.utils.window.googletag.pubads().refresh()
                            this.utils.window.OCM.init_ad_server_set = true
                        } else {
                            setTimeout(() => {
                                this.utils.window.googletag.pubads().refresh()
                                this.utils.window.OCM.init_ad_server_set = true
                            }, 400);
                        }
                    } else {
                        this.utils.window.googletag.pubads().refresh()
                        this.utils.window.OCM.init_ad_server_set = true
                    }
                })
            })
        }
    }

    async buildAdUnits() {
        return new Promise((resolve, reject) => {
            if (this.config.debug || this.hb_config.debug) {
                console.log(LOG_PREFIX + 'Building OCM.ad_units...')
            }
            // Make sure we not loose any existing OCM.ad_units from previous ocm.js scripts
            let existingOcmAdUnits = (this.utils.window.OCM?.ad_units) ? JSON.parse(JSON.stringify(this.utils.window.OCM.ad_units)) : []

            let newOcmAdUnits = this.hb_config.adUnits.map((adunit) => {
                let allowInPageType = true
                let allowInDevice = true
                let allow = true

                allowInPageType = this.filterAdUnitByPageType(adunit)
                allowInDevice = this.filterAdUnitByDevice(adunit)

                allow = (allowInDevice && allowInPageType)

                if (allow) {
                    // jsonify
                    adunit = this.jsonifyAd(adunit)
                    // remove inactive bidders
                    adunit = this.removeInactiveBidderBids(adunit)
                    if (!adunit) {
                        return null
                    }
                    // remove null bid parameters
                    adunit = this.removeNullBidParams(adunit)
                    // apply hb size map
                    adunit = this.hbSizeMap(adunit)
                    if (!adunit) {
                        return null
                    }
                    // remove bad bid sizes
                    adunit = this.removeBadBidSizes(adunit)
                    // geofilter bidders
                    if (this.config.services.geolocation.active && this.utils.window.OCM?.geo?.country) {
                        adunit = this.geoFilter(adunit)
                    }
                    // build mediaTypes
                    adunit = this.buildMediaTypes(adunit)

                    return adunit
                }

            }).filter(item => item !== undefined && item !== null)

            this.utils.window.OCM.ad_units = [...new Set([...existingOcmAdUnits, ...newOcmAdUnits])]
            resolve()
        })
    }

    filterAdUnitByPageType(adunit) {
        if (!this.utils.allowPageType(adunit.page_types)) {
            if (this.config.debug || this.hb_config.debug) {
                console.log(LOG_PREFIX + 'Removing ' + adunit.code + ' due to page type')
            }

            return false
        }

        return true
    }

    filterAdUnitByDevice(adunit) {
        if (this.utils.is_mobile) {
            if (adunit.visibility.hasOwnProperty('mobile') && !adunit.visibility.mobile) {
                if (this.config.debug || this.hb_config.debug) {
                    console.log(LOG_PREFIX + 'Removing ' + adunit.code + ' due to hidden in mobile')
                }

                return false
            }
        } else {
            if (adunit.visibility.hasOwnProperty('desktop') && !adunit.visibility.desktop) {
                if (this.config.debug || this.hb_config.debug) {
                    console.log(LOG_PREFIX + 'Removing ' + adunit.code + ' due to hidden in desktop')
                }

                return false
            }
        }

        return true
    }

    jsonifyAd(adunit) {
        // Transform sizes to arrays
        if (typeof adunit.sizes !== 'undefined') {
            if (!adunit.sizes.ocmStartsWith('[[')) {
                adunit.sizes = '[' + adunit.sizes + ']'
            }

            if (this.utils.hasJsonStructure(adunit.sizes)) {
                adunit.sizes = JSON.parse(adunit.sizes)
            }
        }

        if (adunit.hasOwnProperty('size_mapping')) {
            adunit.size_mapping = adunit.size_mapping.map((sm) => {
                if (this.utils.hasJsonStructure(sm.ad_sizes)) {
                    sm.ad_sizes = JSON.parse(sm.ad_sizes)
                }

                if (this.utils.hasJsonStructure(sm.window_size)) {
                    sm.window_size = JSON.parse(sm.window_size)
                }

                return sm
            })
        }

        // Transform json strings to real objects and handle size param etc.
        let hasCriteo = false
        let hasAmazon = false
        let hasSmart = false
        let has3Lift = false
        let smartSiteId
        let smartPageId
        adunit.bids = adunit.bids.map((bid) => {
            //Loop params of each bid and convert to real json object if it has json structure
            for (let k = 0; k < Object.keys(bid.params).length; k++) {
                // Make bid size parameter machine readable
                let ocm_param_key = Object.keys(bid.params)[k]
                let ocm_param = bid.params[ocm_param_key]
                if (ocm_param_key === 'size' && ocm_param !== null && ocm_param.indexOf('[[') < 0 && bid.bidder !== 'ix') {
                    ocm_param = '[' + ocm_param + ']';
                }

                if (bid.bidder === 'criteo') {
                    hasCriteo = true
                }

                if (bid.bidder === 'amazon') {
                    hasAmazon = true
                }

                if (bid.bidder === 'smartadserver') {
                    hasSmart = true
                    smartSiteId = bid.params.siteId
                    smartPageId = bid.params.pageId
                }

                if (bid.bidder === 'triplelift') {
                    has3Lift = true
                }

                // Turn other json strings to objects
                if (this.utils.hasJsonStructure(ocm_param)) {
                    bid.params[ocm_param_key] = JSON.parse(ocm_param)
                }
            }

            return bid
        }).filter(item => item !== undefined)

        // If criteo bidder already exists in ad unit and video is enabled, add criteo outstream video bid
        if (adunit.media_types.indexOf('video') > -1 || adunit.media_types.indexOf('videoInArticle') > -1) {
            if (hasCriteo) {
                adunit.bids.push({
                    bidder: 'criteo',
                    params: {
                        zoneId: 1697471
                    }
                })
            }

            if (hasAmazon) {
                adunit.bids.push({
                    bidder: 'amazon',
                    params: {
                        slotID: 'Outstream_JS',
                    }
                })
            }

            if (hasSmart) {
                adunit.bids.push({
                    bidder: 'smartadserver',
                    params: {
                        siteId: smartSiteId,
                        pageId: smartPageId,
                        formatId: 67950,
                        domain: '//www14.smartadserver.com'
                    }
                })
            }

            // if (has3Lift) {
            //     adunit.bids.push({
            //         bidder: 'triplelift',
            //         params: {
            //
            //         }
            //     })
            // }
        }

        return adunit
    }

    removeInactiveBidderBids(adunit) {
        for (let i = 0; i < adunit.bids.length; i++) {
            this.hb_config.bidders.forEach((bidder) => {
                if (adunit.bids[i] && adunit.bids[i].bidder === bidder.name && !bidder.active) {
                    // remove bid
                    delete adunit.bids[i]
                }
            })
        }

        adunit.bids = adunit.bids.filter(bid => bid !== null && bid !== 'undefined')

        return (adunit.bids.length) ? adunit : null
    }

    removeNullBidParams(adunit) {
        for (let bid of adunit.bids) {
            for (let param in bid.params) {
                if (bid.params[param] === null) {
                    delete bid.params[param]
                }
            }
        }

        return adunit
    }

    hbSizeMap(adunit) {
        if (typeof adunit.size_mapping !== 'undefined' && adunit.size_mapping.length) {
            // Perform proper size mapping on the ad units
            let size_mapping = adunit.size_mapping
            for (let sm = (size_mapping.length - 1); sm >= 0; sm--) {
                if (this.utils.screen_width >= size_mapping[sm].window_size[0]) {
                    adunit.sizes = size_mapping[sm].ad_sizes
                    break
                }

                if ((sm - 1) >= 0) {
                    if (this.utils.screen_width < size_mapping[sm].window_size[0] && this.utils.screen_width >= size_mapping[(sm - 1)].window_size[0]) {
                        adunit.sizes = size_mapping[(sm - 1)].ad_sizes
                        return adunit
                    }
                } else {
                    if (this.utils.screen_width >= size_mapping[sm].window_size[0]) {
                        adunit.sizes = size_mapping[sm].ad_sizes
                        return adunit
                    }
                }
            }

            // If no sizes were specified, remove the ad unit from going into the pbjs config
            if (adunit.sizes.length === 0) {
                if (this.config.debug || this.hb_config.debug) {
                    console.log(LOG_PREFIX + 'Removing ' + adunit.code + ' due to empty size mapping for this window size')
                }

                adunit = null
            }
        } else {
            // Old school
            if (adunit) {
                // Filter ad unit sizes depending on screen width
                let sizes
                if (this.utils.hasJsonStructure(adunit.sizes)) {
                    sizes = JSON.parse(adunit.sizes)
                } else {
                    sizes = adunit.sizes
                }

                let has_bigger_than_728 = false

                // Check if ad_unit has > 728 sizes
                for (let i = 0; i < sizes.length; i++) {
                    if (sizes[i][0] > 728) {
                        has_bigger_than_728 = true
                    }
                }

                // Remove all sizes larger than the screen width
                for (let k = 0; k < sizes.length; k++) {
                    if (sizes[k][0] > this.utils.screen_width) {
                        if (this.config.debug || this.hb_config.debug) {
                            console.log(LOG_PREFIX + 'Removing ' + sizes[k][0] + ' from' + adunit.code + ' due to screen width')
                        }
                        sizes.splice(k, 1)
                        k--
                    }
                }

                // Remove all sizes below 728 if has_bigger_than 728 and screen width is < 768
                for (let j = 0; j < sizes.length; j++) {
                    if (this.utils.screen_width >= 768 && sizes[j][0] < 728 && has_bigger_than_728) {
                        sizes.splice(j, 1)
                        j--
                    }
                }

                adunit.sizes = JSON.parse(JSON.stringify(sizes))
            }
        }

        return adunit
    }

    removeBadBidSizes(adunit) {
        let keep_bid_sizes = []

        // Transform string arrays to real arrays/objects if key size or outstream_options exists
        adunit.bids = adunit.bids.filter((bid) => {
            keep_bid_sizes = []
            // bid size params
            if (typeof bid.params.size !== "undefined") {
                if (bid.params.size[0] === [1,1] && bid.bidder === 'sovrn') {
                    return true
                }

                if (!Array.isArray(bid.params.size[0]) && adunit.sizes.containsArray(bid.params.size)) {
                    keep_bid_sizes.push(bid.params.size)
                } else if (Array.isArray(bid.params.size[0])) {
                    keep_bid_sizes = bid.params.size.filter((size) => {
                        if (bid.params.size[0][0] === 1 && bid.params.size[0][1] === 1 && bid.bidder === 'sovrn') {
                            return true
                        }

                        return adunit.sizes.containsArray(size)
                    });
                }

                if (keep_bid_sizes.length) {
                    // Keep only proper bid sizes
                    if (bid.bidder === 'ix') {
                        // ix needs [x, y] (array of ints) in the bid request and always has 1 array in the size parm
                        bid.params.size = JSON.parse(JSON.stringify(keep_bid_sizes[0]))
                    } else {
                        // for the rest, size is obsolete for the bid request
                        bid.params.size = JSON.parse(JSON.stringify(keep_bid_sizes))
                    }
                } else {
                    // Remove this bidder from the ad unit since its size should not be used
                    if (this.config.debug || this.hb_config.debug) {
                        console.log(LOG_PREFIX + 'Removing ', bid, ' due to ad unit sizes')
                    }

                    return false
                }
            }

            return true
        })

        return adunit
    }

    geoFilter(adunit) {
        let remove_bidders = []

        for (const bidder of this.hb_config.bidders) {
            if (bidder.geo.length > 0 && bidder.geo.includes(this.utils.window.OCM.geo.country)) {
                if (this.config.debug || this.hb_config.debug) {
                    console.log(LOG_PREFIX + 'Geo-filtering ' + bidder.name + ' (country ' + this.utils.window.OCM.geo.country + ')')
                }
                remove_bidders.push(bidder.name)
            }
        }

        adunit.bids = adunit.bids.filter((bid) => {
            return !remove_bidders.includes(bid.bidder);
        })

        return adunit
    }

    buildMediaTypes(adunit) {
        let media_types = {}

        if (typeof adunit.media_types !== 'undefined') {
            if (adunit.media_types.includes('banner')) {
                media_types.banner = {
                    sizes: (typeof adunit.sizes === 'string') ? JSON.parse(adunit.sizes) : adunit.sizes
                }
            }

            if (adunit.media_types.indexOf('native') > -1) {
                media_types.native = adunit.native
                if (this.utils.hasJsonStructure(media_types.native.image.sizes)) {
                    media_types.native.image.sizes = JSON.parse(media_types.native.image.sizes)
                }
            }

            if (adunit.media_types.indexOf('videoInArticle') > -1 || adunit.media_types.indexOf('video') > -1) {
                media_types.video = {}
                media_types.video.context = 'outstream'
                if (this.utils.hasJsonStructure(adunit.video.playerSize)) {
                    media_types.video.playerSize = (!this.utils.is_mobile) ? JSON.parse(adunit.video.playerSize) : [[300, 169]]
                } else {
                    media_types.video.playerSize = (!this.utils.is_mobile) ? adunit.video.playerSize : [[300, 169]]
                }

                media_types.video.mimes = ['video/mp4', 'application/javascript']
                media_types.video.pos = 1
                media_types.video.delivery = [2]
                media_types.video.minduration = 5
                media_types.video.maxduration = 30
                media_types.video.protocols = [2, 3, 5, 6, 7, 8]
                media_types.video.api = [1, 2]
                media_types.video.skip = 0
                media_types.video.startdelay = 0
                media_types.video.linearity = 1
                media_types.video.placement = (media_types.video.playerSize[0][0] === 640 && !this.utils.is_mobile) ? 3 : 2
                media_types.video.playbackmethod = [1, 2, 4, 5, 6]
                media_types.video.playbackend = 2

                media_types.video.renderer = {
                    url: 'https://cdn.orangeclickmedia.com/tech/libs/ima-ad-player.min.js',
                    backupOnly: false,
                    render: (bid) => {
                        try {
                            let adDiv = this.getAdDivElement(adunit.code)
                            if (!adDiv) {
                                console.error('Could not find element to render outstream ad', bid)
                                return
                            }

                            const imaPlayer = new ImaPlayer(this.utils, adDiv, {
                                width: media_types.video.playerSize[0][0],
                                height: media_types.video.playerSize[0][1]
                            }, bid)
                            imaPlayer.run()
                        } catch (e) {
                            console.error(e)
                            console.error("Error in ad rendering!")
                        }
                    }
                }
            }
        } else {
            media_types.banner = {
                sizes: (typeof adunit.sizes === 'string') ? JSON.parse(adunit.sizes) : adunit.sizes
            }
        }

        adunit.mediaTypes = media_types

        return adunit
    }

    /**
     * Adjusts bidderTimeout if device is mobile, depending on hb mode
     * @param isLazyLoad
     */
    adjustBidderTimeout(isLazyLoad) {
        this.hb_config.bidderTimeout = Number(this.hb_config.bidderTimeout)
        //timeout to control how long bidders have to respond.
        if (this.config.debug || this.hb_config.debug) {
            console.log(LOG_PREFIX + 'bidderTimeout = ' + this.hb_config.bidderTimeout)
        }

        // increase OCM_PREBID_TIMEOUT by 100% if the device is mobile
        if (this.utils.is_mobile) {
            if (isLazyLoad) {
                this.hb_config.bidderTimeout += (this.hb_config.bidderTimeout * .5)
            } else {
                this.hb_config.bidderTimeout += this.hb_config.bidderTimeout
            }

            if (this.config.debug || this.hb_config.debug) {
                console.log(LOG_PREFIX + 'Mobile device, so bidderTimeout = ', this.hb_config.bidderTimeout)
            }
        }
    }

    /**
     * Load the Prebid Javascript Library Async. We recommend loading it immediately after
     * the initAdserver() and setTimeout functions.
     */
    loadPrebidLib() {
        if (this.config.debug || this.hb_config.debug) {
            console.log(LOG_PREFIX + 'Loading OCM prebid library...')
        }

        let pbs = this.utils.window.document.createElement("script")
        pbs.type = "text/javascript"
        pbs.async = true
        pbs.src = 'https://cdn.orangeclickmedia.com/tech/libs/ocmpb.js' //v7.32.0-pre

        let node = this.utils.window.document.getElementsByTagName('script')[0]
        node.parentNode.insertBefore(pbs, node)
    }

    loadAdformCookie() {
        let image = this.utils.window.document.createElement('img')
        image.src = "https://track.adform.net/Serving/TrackPoint/?pm=2776384"
        image.setAttribute('style', 'display:none')
        let body = this.utils.doc_body || this.utils.window.document.body
        body.insertAdjacentElement('beforeend', image)
    }

    prebidEventListeners() {
        if (this.config.debug || this.hb_config.debug) {
            console.log(LOG_PREFIX + 'Setting up Prebid events for HB')
        }

        if (this.config.log) {
            // adRenderFailed
            this.utils.window.ocmpbjs.que.push(() => {
                this.utils.window.ocmpbjs.onEvent('adRenderFailed', (event) => {
                    if (event.bid.vastXml) {
                        delete event.bid.vastXml
                    } // to save space

                    this.utils.log.error({
                        service: 'header_bidding',
                        msg: 'adRenderFailed',
                        error: event
                    })
                })
            })

            // auctionDebug filtered
            this.utils.window.ocmpbjs.que.push(() => {
                this.utils.window.ocmpbjs.onEvent('auctionDebug', (event) => {
                    this.utils.calculateLoggingSamples()
                    try {
                        if (event.type !== 'ERROR' && event.type !== 'WARNING') {
                            return
                        }

                        let msg = ''
                        if (event.arguments.length) {
                            for (let i = 0; i < event.arguments.length; i++) {
                                if (!event.arguments[i]) {
                                    continue;
                                }

                                if (typeof event.arguments[i] === 'string' && event.arguments[i].indexOf('Invalid bid sent to bidder') > -1) { // keep params + ad unit code
                                    let split = event.arguments[i].split(': ')
                                    let bid = JSON.parse(split[1])
                                    msg += 'Invalid bid sent to bidder ' + JSON.stringify({
                                        bidder: bid.bidder,
                                        params: bid.params,
                                        adUnitCode: bid.adUnitCode
                                    })
                                } else {
                                    msg += JSON.stringify(event.arguments[i]);
                                }
                            }
                        }

                        if (msg === '') {
                            return
                        }

                        for (let i = 0; i < this.utils.log_blacklist.length; i++) {
                            if (msg.indexOf(this.utils.log_blacklist[i]) > -1) {
                                return
                            }
                        }

                        if (event.type === 'ERROR') {
                            if (this.utils.willSampleErrors) {
                                this.utils.log.error({
                                    service: 'header_bidding',
                                    msg: 'auctionDebug ERROR',
                                    error: msg
                                })
                            }
                        } else {
                            if (this.utils.willSampleWarnings) {
                                this.utils.log.warning({
                                    service: 'header_bidding',
                                    msg: 'auctionDebug WARNING',
                                    error: msg
                                })
                            }
                        }
                    } catch (error) {
                        console.error('inside auctionDebug listener', error)
                    }
                })
            })
        }
    }

    gptEventListeners() {
        if (this.config.debug || this.hb_config.debug) {
            console.log(LOG_PREFIX + 'Setting up GPT events for HB')
        }

        let hb_keys = []
        this.utils.window.googletag.cmd.push(() => {
            this.utils.window.googletag.pubads().addEventListener('slotRenderEnded', (event) => {
                // inread_pb passback call
                if (event.slot.getAdUnitPath().indexOf('inread_pb') > -1 && event.isEmpty) {
                    // Call our global oopb()
                    if (typeof this.utils.window.oopb !== "undefined") {
                        this.utils.window.oopb()
                    }
                }

                hb_keys = []
                // Clear all important hb_* targeted keys from the slot to avoid staleRendering
                let hb_keys = event.slot.getTargetingKeys().map((key) => {
                    if (key.indexOf('hb_') > -1 && key.indexOf('amzn') > -1) {
                        return key
                    }
                }).filter(item => item !== undefined)

                hb_keys.forEach((key) => {
                    event.slot.clearTargeting(key)
                })
            })
        })
    }

    setupRealTimeData() {
        return this.hb_config?.adsqRTDModuleEnabled ? {
            // solution for ocm-gone-mad only DSGs
            auctionDelay: (this.utils.window.OCM.ad_units.length === 1 && this.utils.window.OCM.ad_units[0].code === 'ocm-gone-mad') ? 1500 : 700,
            dataProviders: [{
                name: "adsquirrel",
                waitForIt: true,
                params: {}
            }]
        } : {}
    }

    setupAnalytics() {
        if (typeof this.hb_config.analytics !== 'undefined') {
            if (this.hb_config.analytics.indexOf('ocmpba') >= 0) {
                this.analytics_adapters.push({provider: 'ocmpba'})
            }
            if (this.hb_config.analytics.indexOf('ga') >= 0) {
                this.analytics_adapters.push({provider: 'ga', options: {enableDistribution: false}})
            }
            if (this.hb_config.analytics.indexOf('roxot') >= 0) {
                this.analytics_adapters.push({
                    provider: 'roxot',
                    options: {publisherIds: ["6e522d36-dae0-45bd-9d3c-de88ef4334ee"]}
                })
            }

            // if (this.hb_config.analytics.indexOf('rivr') > -1) {
            //     ocmpbjs.que.push(() => {
            //         let RIVR_CLIENT_ID = "ocm"
            //         let RIVR_CLIENT_AUTH_TOKEN = "b2NtOlJpPUdBUEMvOEJKcUQ1JA=="
            //
            //         let args = {
            //             clientID: RIVR_CLIENT_ID,
            //             authToken: RIVR_CLIENT_AUTH_TOKEN,
            //             siteCategories: [],
            //             pbjs: window.ocmpbjs,
            //         }
            //
            //         if (window.rivraddon) {
            //             rivraddon.enable(args)
            //         } else {
            //             window.RIVR_ARGS = args
            //         }
            //     })
            // }
        }
    }

    setupBidderSettings(hasCustomGranularity) {
        if (this.config.debug || this.hb_config.debug) {
            console.log(LOG_PREFIX + 'Setting up adserverTargeting & bidder settings')
        }

        let bidderSettings = {
            standard: {
                suppressEmptyKeys: true,
                adserverTargeting: [
                    {
                        key: "infi_article_count",
                        val: (bidResponse) => {
                            return this.utils.window.OCM?.infi_article_count.toString()
                        }
                    },
                    {
                        key: "hb_bidder",
                        val: (bidResponse) => {
                            return bidResponse.bidderCode
                        }
                    }, {
                        key: "hb_adid",
                        val: (bidResponse) => {
                            return bidResponse.adId
                        }
                    }, {
                        key: "hb_pb",
                        val: (bidResponse) => {
                            return (hasCustomGranularity) ? bidResponse.pbCg : bidResponse.pbHg
                        },
                    }, {
                        key: "hb_size",
                        val: (bidResponse) => {
                            return bidResponse.getSize()
                        }
                    }, {
                        key: "hb_format",
                        val: (bidResponse) => {
                            return bidResponse.mediaType
                        }
                    }, {
                        key: "hb_cpm",
                        val: (bidResponse) => {
                            return bidResponse.cpm
                        }
                    }, {
                        key: 'hb_deal',
                        val: (bidResponse) => {
                            return bidResponse.dealId
                        }
                    }, {
                        key: 'hb_source',
                        val: (bidResponse) => {
                            return bidResponse.source
                        }
                    }, {
                        key: 'hb_sf',
                        val: (bidResponse) => {
                            // let nsf_bidders = ['justpremium', 'teads', 'gumgum', 'triplelift', 'adhash', 'engageya']
                            // let is_video = (bidResponse.mediaType === 'video')
                            // return (nsf_bidders.includes(bidResponse.bidderCode) || is_video) ? 'false' : 'true';
                            return 'false'
                        }
                    }, {
                        key: 'hb_cache_id',
                        val: (bidResponse) => {
                            return bidResponse.videoCacheKey
                        }
                    }, {
                        key: 'hb_uuid',
                        val: (bidResponse) => {
                            return bidResponse.videoCacheKey
                        }
                    }
                ]
            }
        }

        let bidder
        let tmp_bid_cpm_adjust = 1.0
        let ocm_bidder_name = ''
        // Loop through all bidders and push the configuration to the bidderSettings
        if (typeof this.hb_config.bidders !== 'undefined') {
            for (bidder = 0; bidder < this.hb_config.bidders.length; bidder++) {
                tmp_bid_cpm_adjust = parseFloat(this.hb_config.bidders[bidder].bid_cpm_adjust)
                ocm_bidder_name = this.hb_config.bidders[bidder].name
                bidderSettings[this.hb_config.bidders[bidder].name] = {
                    storageAllowed: true,
                    bidCpmAdjustment: (bidCpm, bid, ocm_bidder_name) => {
                        switch (ocm_bidder_name) {
                            case 'smartadserver':
                                if (bid.netRevenue === true) {
                                    tmp_bid_cpm_adjust = 1
                                } else {
                                    if (bid.dealId !== "") {
                                        tmp_bid_cpm_adjust = 0.9
                                    }
                                }
                                break
                            case 'improvedigital':
                                if (bid.isNet === true) {
                                    tmp_bid_cpm_adjust = 1
                                } else {
                                    if (bid.dealId !== "") {
                                        tmp_bid_cpm_adjust = 0.9
                                    }
                                }
                                break
                            default:
                                tmp_bid_cpm_adjust = 1.0
                                break
                        }

                        return bidCpm * tmp_bid_cpm_adjust
                    }
                }
            }
        }

        return bidderSettings
    }

    getAdDivElement(adUnitCode) {
        let adDiv = null
        if (this.utils.config.services.header_bidding.functionality === 'no_adserver') {
            return this.utils.window.document.getElementById(adUnitCode)
        }

        if (this.utils.config.services.lazyload.active) {
            adDiv = this.utils.window.document.querySelector('[data-oau-code="' + adUnitCode + '"]')
        } else {
            let slot = this.utils.window.googletag.pubads().getSlots().filter((slot) => {
                return slot.getAdUnitPath() === adUnitCode
            })
            if (slot.length && typeof slot[0] === 'object') {
                adDiv = this.utils.window.document.getElementById(slot[0].getSlotElementId())
            }
        }

        return adDiv
    }

    directRenderBids(bidResponses) {
        for (let adUnitCode in bidResponses) {
            if (this.config.debug || this.hb_config.debug) {
                console.log(LOG_PREFIX + 'Found bid for #' + adUnitCode)
            }

            const divId = adUnitCode;
            const adDiv = this.utils.window.document.getElementById(divId)

            if (adDiv) {
                if (this.config.debug || this.hb_config.debug) {
                    console.log(LOG_PREFIX + 'Ad div = ', adDiv)
                }

                let frmEl = this.utils.window.document.createElement('iframe');

                frmEl.setAttribute('id', divId + '_ocm_hb')
                frmEl.setAttribute('style', 'border:0; overflow:hidden; margin:0px auto;display:block;');
                frmEl.width = '1'
                frmEl.height = '1'
                frmEl.setAttribute('frameborder', '0')
                frmEl.setAttribute('marginheight', '0')
                frmEl.setAttribute('marginwidth', '0')
                frmEl.setAttribute('scrolling', 'no')
                frmEl.setAttribute('sandbox', 'allow-forms allow-popups allow-popups-to-escape-sandbox allow-pointer-lock allow-same-origin allow-scripts allow-top-navigation-by-user-activation')

                let adServerTargeting = this.utils.window.ocmpbjs.getAdserverTargetingForAdUnitCode(divId)
                if (this.config.debug || this.hb_config.debug) {
                    console.log(LOG_PREFIX + 'adServerTargeting', adServerTargeting)
                }

                // If any bidders return any creatives
                if (adServerTargeting && adServerTargeting['hb_adid']) {
                    if (this.config.debug || this.hb_config.debug) {
                        console.log(LOG_PREFIX + 'Rendering ad for ' + divId)
                    }

                    // remove deprecated divs
                    const deprecatedElements = adDiv.querySelectorAll('script, div, iframe, ins');
                    deprecatedElements.forEach(element => element.remove());

                    adDiv.append(frmEl);
                    // frame body margin:8px 0 fix (?)
                    let iframeDoc = frmEl.contentDocument || frmEl.contentWindow.document

                    this.utils.window.ocmpbjs.renderAd(iframeDoc, adServerTargeting['hb_adid'])

                    setTimeout(()=> {
                        if (iframeDoc.body) {
                            iframeDoc.body.insertAdjacentHTML('afterbegin', `<style>body {margin:0px !important}</style>`)
                            iframeDoc.body.style.margin = '0px !important'
                        }
                    }, 10)

                    adDiv.setAttribute('style', 'display:block;overflow:hidden;margin:0 auto;');
                }
            } else {
                if (this.config.debug || this.hb_config.debug) {
                    console.log(LOG_PREFIX + 'Ad div #' + divId + ' not found')
                }
            }
        }
    }
}
