// Here we goooooo!!!
// import * as Sentry from "@sentry/browser";
// import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
// if (document.currentScript.hasAttribute('crossorigin') && document.currentScript.getAttribute('crossorigin') === 'anonymous') {
//     Sentry.init({
//         dsn: "https://cd9bc5533e0348f686e35e964bcaa89b@o774947.ingest.sentry.io/5796745",
//         // -- START Event Filtering (remove known non-bugs)
//         beforeSend(event, hint) {
//             const error = hint.originalException;
//
//             // fun-hooks: referenced 'registerAdserver' but it was never created
//             if (error && error.message && error.match(/registerAdserver/i)) {
//                 return null
//             }
//
//             // ERROR: Server call for {BIDDER} failed: . Continuing without bids
//             if (error && error.message && error.match(/Continuing without bids/i)) {
//                 return null
//             }
//         },
//         // -- END Event Filtering (remove known non-bugs)
//         integrations: [new CaptureConsoleIntegration({
//                 // array of methods that should be captured
//                 // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
//                 levels: ['error', 'warn']
//             }
//         )],
//         debug: false,
//         tracesSampleRate: 1.0
//     });
// }

(()=> {
    const config = require('./config')
    const Utils = require('../../includes/utils')
    const utils = new Utils(config)

    if (utils.getParameterByName('ocm_dev') == '1' && config.env === 'prod') {
        let dev_script = document.createElement('script')
        dev_script.src = `//cdn.orangeclickmedia.com/tech/dev/${config.script_tag}/ocm.js`
        utils.doc_head.append(dev_script)

        return
    }

    let win = config.hasOwnProperty('use_window_top') ? ( config.use_window_top  ? window.top:  window ) : window.top

// Globals
    if (!Array.prototype.containsArray) {
        Object.defineProperty(Array.prototype, "containsArray", {
            enumerable: false,
            value: function (val) {
                let hash = {}
                for (let i = 0; i < this.length; i++) {
                    hash[this[i]] = i
                }
                return hash.hasOwnProperty(val)
            }
        });
    }

    if (!String.prototype.ocmStartsWith) {
        Object.defineProperty(String.prototype, 'ocmStartsWith', {
            value: function (search, rawPos) {
                let pos = rawPos > 0 ? rawPos | 0 : 0;
                return this.substring(pos, pos + search.length) === search;
            }
        });
    }

// Globals
    win.googletag = win?.googletag || {cmd: []}

    const dateOpt = {
        hour: "2-digit", minute: "2-digit", second: "2-digit"
    }

    const globals = {
        pageType: window.top?.OCM?.pageType || window?.OCM?.pageType || 'ROS',
        adBlocked: window.top?.OCM?.adBlocked || window.OCM?.adBlocked || true,
        _log: (utils.config?.log) ? utils.log : null,
        geo: window?.ADSQ?.response?.geo || null,
        hb_mode: '',
        uam_done: false,
        pb_done: false,
        initial_ad_units: [],
        ad_units: window.top?.OCM?.ad_units || window?.OCM?.ad_units || [],
        infi_article_count: 1,
        init_ad_server_set: false,
        first_gpt_slots_length: 0,
        site_categories: config?.site_categories  || []
    }
// -- Globals

    win.OCM = (win.OCM) ? {...win.OCM, ...globals} : globals

// Determine if debug was explicitly requested through a url param (ocm_debug=true)
    let debug = utils.getParameterByName('ocm_debug');
    if (debug) {
        config.debug = true
    }

    const init = require('../../includes/init')

    const is_mobile = utils.isMobile()
    win.OCM.isMobile = is_mobile
    const pageSense = require('./pagesense')

// If quantcast.active && quantcast.network_id
    if (config.services.cmp.active && config.services.cmp.network_id) {
        const cmp = require('../../services/cmp')
        cmp(config)
    }

    win.googletag = win?.googletag || {cmd: []}

// then embed ocm_iab_compliance process
if (config.services.iab_compliance.active) {
    const iab_compliance = require('../../services/iab_compliance')
    iab_compliance(config).then(async () => {
        await init(pageSense, utils, config)
        // Run custom code from DSG
        const customCode = require('./custom')
        await customCode(utils)
        // -- Run custom code from DSG
    }).catch((error) => {
        console.error('[IAB COMPLIANCE] Error:', error)
        // utils.log.error('iab_compliance', '', error, config.build, win.location.hostname, win.location.href)
    })
} else {
    (async () => {
        await init(pageSense, utils, config)
        // Run custom code from DSG
        const customCode = require('./custom')
        await customCode(utils)
        // -- Run custom code from DSG
    })()
}
// Aaaaaand DONE!
})()
