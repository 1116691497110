const LOG_PREFIX = '[OCM][IMA-PLAYER] '

module.exports = class ImaPlayer {
    adDiv
    size
    bid
    utils
    config
    hb_config
    vastUrl
    vastXml
    videoElement
    adContainer
    controlsContainer
    playButton
    playState
    volumeButton
    volumeState
    viewportObserver
    player
    visible
    playerState

    constructor(utils, adDiv, size, bid) {
        this.utils = utils
        this.config = this.utils.config
        this.hb_config = this.utils.config.services.header_bidding
        this.adDiv = adDiv
        this.size = size
        this.bid = bid

        this.vastUrl = this.bid.vastUrl || null
        this.vastXml = this.bid.vastXml || null

        this.playerState = false
        this.visible = false
        this.volumeState = false
        this.playState = false
    }

    run() {
        this.styles()
        this.html()
        // this.observer()

        this.AdPlayer()
    }

    AdPlayer() {
        let vastSource = 'url'
        if (this.vastUrl && !this.vastXml) {
            vastSource = 'url'
        } else if (this.vastXml) {
            vastSource = 'xml'
        }

        let adsRequestOptions = {
            vastLoadTimeout: 10000,
        }

        if (vastSource === 'xml') {
            adsRequestOptions = {
                vastLoadTimeout: 10000,
                adsResponse: this.vastXml
            }
        }

        if (this.utils.config.debug || this.utils.config.services.header_bidding.debug) {
            console.log(LOG_PREFIX + 'vastUrl', this.vastUrl)
            console.log(LOG_PREFIX + 'adsRequestOptions', adsRequestOptions)
        }

        ImaAdPlayer({
            video: this.videoElement,
            displayContainer: this.adContainer,
            vpaidMode: ImaAdPlayer.vpaidMode.INSECURE,
            locale: (window?.navigator?.language) ? window.navigator.language : 'en',
            tag: (vastSource === 'url') ? this.vastUrl : '',
            maxDuration: 30000,
            restoreVideo: true,
            adsRequestOptions: adsRequestOptions,
            adsRenderingOptions: {
                loadVideoTimeout: 10000,
                useStyledLinearAds: false,
                useStyledNonLinearAds: false,
            },
            adWillPlayMuted: true,
            timeout: 5000,
            debug: (this.utils.config.debug || this.hb_config.debug),
        }, (player, error) => {
            this.player = player

            try {
                window.google.ima.settings.setDisableCustomPlaybackForIOS10Plus(true);
            } catch(err) {
                console.log(LOG_PREFIX, err)
            }

            if (error) {
                let err = o.data?.j?.j
                this.handleAdError(err)
                return
            }

            this.player.on('ad_play', (o) => {
                this.handleAdPlay()
            })

            this.player.on('loaded', (o) => {
                this.handleLoaded(o)
            })

            this.player.on('impression', (o) => {
                this.handleAdImpression()
            })

            this.player.on('ad_error', (o) => {
                let error = o.data?.j?.j
                this.handleAdError(error)
            })

            this.player.on('ad_end', (o) => {
                this.handleAdEnd()
            })

            this.player.on('click', (o) => {
                this.handleVideoEvents('click', o)
            })

            this.player.on('first_quartile', (o) => {
                this.handleVideoEvents('first_quartile', o)
            })

            this.player.on('midpoint', (o) => {
                this.handleVideoEvents('midpoint', o)
            })

            this.player.on('third_quartile', (o) => {
                this.handleVideoEvents('third_quartile', o)
            })

             this.player.on('complete', (o) => {
                this.handleVideoEvents('complete', o)
            })

            this.player.play()

            if (this.controlsContainer) {
                let style = window.getComputedStyle(this.controlsContainer)
                if (style.display == 'none') {
                    this.controlsContainer.setAttribute('style', 'display:block !important');
                }
            }
        })
    }

    // observer() {
    //     this.viewportObserver = new IntersectionObserver((entries, self) => {
    //         entries.forEach((entry) => {
    //             this.visible = entry.isIntersecting;
    //
    //             if (this.playerState) {
    //                 this.visible ? this.player.resume() : this.player.pause()
    //             }
    //         })
    //     })
    // }

    // ad player is ready to play ad. (alias of "content_pause_requested" event)
    handleLoaded(o) {
        try {
            if (!o.data.B.vpaid) {
                // this.viewportObserver.observe(this.videoElement)
                this.playerState = true

                setTimeout(() => {
                    if (this.playerState) {
                        this.visible ? this.player.play() : this.player.pause()
                    }
                }, 1000)
            }
        } catch (error) {
            // Not a vpaid, observe normally
            // this.viewportObserver.observe(this.videoElement)
            this.playerState = true

            setTimeout(() => {
                if (this.playerState) {
                    this.visible ? this.player.play() : this.player.pause()
                }
            }, 1000)
        }

        this.resizeAd()
    }

    resizeAd() {
        if (this.bid.width <= this.size.width) {
            return
        }

        // Bid response ratio
        let bidResponseRatio = this.bid.height / this.bid.width
        this.size.height = Math.floor(this.size.width * bidResponseRatio)

        this.player.resize(this.size.width, this.size.height);
    }

    // requested ad is attempted to be played.
    handleAdPlay() {
        this.controlsMute()
        this.player.setVolume(0)

        // adRenderSucceeded for video impressions
        // if (this.config.log) {
        //     this.utils.log.warning({
        //         service: 'header_bidding',
        //         msg: 'Video ad_play',
        //         error: {
        //             bidder: this.bid.bidderCode,
        //             adUnitCode: this.bid.adUnitCode,
        //             vastUrl: this.vastUrl,
        //             vastXml: this.vastXml,
        //             widthRequest: this.size.width,
        //             heightRequested: this.size.height,
        //             widthResponded: this.bid.width,
        //             heightResponded: this.bid.height
        //         }
        //     })
        // }
    }

    handleAdImpression() {
        // Send to jaina
        if (this.utils.window.OCM?.vidimps && this.utils.window.OCM.vidimps[this.bid.auctionId]) {
            let eventData = this.utils.window.OCM.vidimps[this.bid.auctionId]
            // delete this.utils.window.OCM.vidimps[this.bid.auctionId]
            fetch('https://windrunner.orangeclickmedia.com/pba-update', {
                method: 'POST',
                headers: {
                    'Content-Type': 'text/plain'
                },
                body: JSON.stringify(eventData)
            }).then((response) => {
                response.json().then((json) => console.log(json))
            }).catch((err) => {
                console.error(err)
            })
        }
    }

    handleVideoEvents(eventName, object) {
        if (this.utils.window.OCM?.vidimps && this.utils.window.OCM.vidimps[this.bid.auctionId]) {
            const eventData = this.utils.window.OCM.vidimps[this.bid.auctionId]
            const data = {
                eventName: eventName,
                adUnit: eventData.data.adUnit,
                auctionId: eventData.data.auctionId
            }

            fetch('https://windrunner.orangeclickmedia.com/pba-video', {
                method: 'POST',
                headers: {
                    'Content-Type': 'text/plain'
                },
                body: JSON.stringify(data)
            }).then((response) => {
                response.json().then((json) => {/* console.log(json) */})
            }).catch((err) => {
                console.error(err)
            })
        }
    }

    handleAdEnd() {
        // this.viewportObserver.unobserve(this.videoElement)
        this.player.destroy()
        this.videoElement.style.height = 0
        setTimeout(() => {
        	this.videoElement.remove()
        }, 500)

        this.refreshAd()
    }

    handleAdError(o) {
        if (this.config.log) {
            this.utils.log.error({
                service: 'header_bidding',
                msg: 'Video ad_error',
                error: {
                    msg: o,
                    bidder: this.bid.bidderCode,
                    adUnitCode: this.bid.adUnitCode,
                    vastUrl: this.vastUrl,
                    vastXml: this.vastXml
                }
            })
        }

        this.refreshAd()
    }

    refreshAd() {
        if (this.hb_config.functionality === 'no_adserver') {
            // Do not refresh outstream ad if functionality = no_adserver
            return
        }

        if (this.hb_config.functionality.includes('lazyload')) {
            this.resetLazyLoadForAdDiv()
        } else {
            let slot = this.utils.window.googletag.pubads().getSlots().filter((slot) => {
                return slot.getSlotElementId() === this.adDiv.id
            })

            if (slot.length) {
                this.utils.window.googletag.pubads().refresh(slot)
            }
        }
    }

    resetLazyLoadForAdDiv() {
        this.adDiv.removeAttribute('data-lazyloaded-by-ocm')
        this.adDiv.removeAttribute('data-oau-code')
    }

    html() {
        let ocmPlayer =
            `<div id="ocm-video-${this.adDiv.id}" class="ocm-video-player">`+
            `  <div id="ocm-ad-container-${this.adDiv.id}"></div>`+
            `  <div class="ocm-ad-controls">`+
            `    <div class="ocm-ad-label">Ad</div>`+
            `    <div class="ocm-ad-play"></div>`+
            `    <div class="ocm-ad-volume"></div>`+
            `    <div class="ocm-ad-replay"></div>`+
            `  </div>`+
            `</div>`

        this.adDiv.insertAdjacentHTML('afterbegin', ocmPlayer)

        this.videoElement = document.getElementById(`ocm-video-${this.adDiv.id}`);
        this.adContainer = document.getElementById(`ocm-ad-container-${this.adDiv.id}`);
        this.controlsContainer = document.querySelector(`#ocm-video-${this.adDiv.id} .ocm-ad-controls`);
        this.volumeButton = document.querySelector(`#ocm-video-${this.adDiv.id} .ocm-ad-controls .ocm-ad-volume`);

        if (this.size.width === 300) {
            this.controlsContainer.classList.add('scaled')
            this.volumeButton.classList.add('scaled')
        }

        this.elementListeners()
    }

    elementListeners() {
        // this.playButton.addEventListener('click', (e) => {
        //     if (this.playState) {
        //         // this.controlsPause()
        //         this.player.pause()
        //     } else {
        //     	this.controlsPlay()
        //     	this.player.resume()
        //     }
        // })

        this.volumeButton.addEventListener('click', (e) => {
            if (this.volumeState) {
                this.controlsMute()
                this.player.setVolume(0)
            } else {
                this.controlsUnMute()
                this.player.setVolume(0.6)
            }
        })
    }

    // controlsPlay() {
    //     this.playState = true
    //     this.playButton.classList.remove('paused')
    //     this.playButton.classList.add('playing')
    // }
    //
    // controlsPause() {
    //     this.playState = false
    //     this.playButton.classList.remove('playing')
    //     this.playButton.classList.add('paused')
    // }

    controlsMute() {
        this.volumeState = false
        this.player.setVolume(0)
        this.volumeButton.classList.remove('unmuted')
        this.volumeButton.classList.add('muted')
    }

    controlsUnMute() {
        this.volumeState = true
        this.player.setVolume(1)
        this.volumeButton.classList.remove('muted')
        this.volumeButton.classList.add('unmuted')
    }

    styles() {
        this.utils.loadStyle(`
        .ocm-video-player {
           display:block; margin:0 auto; width:${this.size.width}px; height:${this.size.height}px; transition: height 1s, transform 1s;
        }
        .ocm-ad-controls {
        	display:none;
        	width:0px;
        	height:auto;
        	bottom:0px;
        	position: relative;
        	z-index:101;
        }
        /*.ocm-ad-play, */
        .ocm-ad-volume {
        	position:absolute;
        	top:10px;
        	width:32px;
        	height:32px;
        	cursor: pointer;
        	border-radius:50%;
        	background-color: rgb(223 129 0 / 85%);
        	background-origin:border-box;
        	background-position:center center;
        	background-repeat: no-repeat;
        	background-size:20px;
        }
        .ocm-ad-label {
        	position:absolute;
        	top:16px;
        	left:50px;
        	width:16px;
        	height:16px;
        	font-size:12px;
        	color:#000;
        	text-shadow:1px 1px 0px rgba(255, 255, 255, 0.6)
        }
        .scaled {
            transform: scale(0.8) !important;        
        }
        .ocm-ad-label.scaled {
            left: 46px !important;
        }
        .ocm-ad-volume {
        	left:10px;
        }
        /*
        .ocm-ad-play {
    		left:10px;
        }
        .ocm-ad-play.playing {
        	background-image:url('//cdn.orangeclickmedia.com/assets/outstream-player/pause.png');
        }
        .ocm-ad-play.paused {
        	background-position: 9px 6px;
		    background-size: 18px;
        	background-image:url('//cdn.orangeclickmedia.com/assets/outstream-player/play.png');
        }
        */
        .ocm-ad-volume.unmuted {
        	background-image:url('//cdn.orangeclickmedia.com/assets/outstream-player/mute.png');
        }
        .ocm-ad-volume.muted {
        	background-position: 9px 9px;
        	background-image:url('//cdn.orangeclickmedia.com/assets/outstream-player/unmute.png');
        }
        `)
    }
}
